import React, { useEffect, useContext, useState } from 'react';
//import { Col, Row, Card, Form } from 'react-bootstrap';
import {Alert, Input} from 'reactstrap';
import { Button, Col, Row, Card, Form } from 'react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import axios from "axios";
import {Auth} from 'aws-amplify';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import Preloader from "../../components/Preloader";
import {Helmet} from 'react-helmet-async';
import { phoneCode, journalCategory } from "../../ProData";
import ProfileImage from '../profile/ProfileImage';

//import RegistrationForm from '../Registrations/RegistrationForm';
const config = require('../../config.json');
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const phoneRegex=/^\+[1-9]{1}[0-9]{3,14}$/;
const dobRegex=/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;


export default function AdminForm(props) {
  //const history=useHistory();
  const {formType, newToggle} = props;
  const { loggedUser, alertDetails, setAlertDetails, alertClose, validateLogin, generateDate, newImage, setNewImage, churchMaster} = useContext(ProductContext);
  const [loaded, setLoaded] = useState(false);
  const [viewOnly, setViewOnly] = useState(true);
  const [adminDetails, setAdminDetails] = useState({adminID:'', title:'Mr',adminName:'', areaCode:'+91', 
  adminPhoneNo:'', adminEmailID:'', chruchRole:'Admin', registeredDate:'', adminStatus:'Active'});
  const [profileImage, setProfileImage] = useState('defaultAvatar.jpg');
  const [newProfileImage, setNewProfileImage]=useState('defaultAvatar.jpg')

  
  //const [imageFile, setImageFile] = useState('');
 // const [profileImage,setProfileImage]=useState('https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg');

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      if(formType==="New"){
        setViewOnly(false);
        resetValues();
        setLoaded(true);

      }else if(formType==="View"){
        setLoaded(false);
        const clientID=loggedUser.familyName;
       // console.log("image : ", `${config.s3.imageURL}${clientID}${"/admins/"}${props.selectedItem.adminID}${"/"}${props.selectedItem.adminID}${".jpeg"}`);
        setProfileImage(`${config.s3.imageURL}${clientID}${"/admins/"}${props.selectedItem.adminID}${"/"}${props.selectedItem.adminID}${".jpeg"}`);
        setViewOnly(true);
        setAdminDetails(props.selectedItem);
        setLoaded(true);

        //console.log("props : ", props);
      }else{
      fetchMyProfile();
      }
    }else{
      validateLogin();
    }
  },[loggedUser])

  const fetchMyProfile=async()=>{
    setLoaded(false);
    try{
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          const searchOption="AdminID";
          const clientID=loggedUser.familyName;
          //console.log("logged user : " , loggedUser);
          await axios.get(`${config.api.invokeUrl}/dashboard/adminform?fetchKey=${loggedUser.givenName}&fetchOption=${searchOption}&clientID=${clientID}`, {
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
           //console.log("response : ", response.data[0]);
            if(response.data.length>0)
            {
                setAdminDetails(response.data[0]);
                //console.log("image : ", `${config.s3.imageURL}${clientID}${"/admins/"}${response.data[0].adminID}${"/"}${response.data[0].adminID}${".jpeg"}`);
                await setProfileImage(`${config.s3.imageURL}${clientID}${"/admins/"}${response.data[0].adminID}${"/"}${response.data[0].adminID}${".jpeg"}`);
                
                //setProfileDetails(response.data[0]);
                setAlertDetails({
                  ...alertDetails,
                  style:"success",
                  open:true,
                  message: "Profile loaded Successfully!"
              });  
              setLoaded(true);
            }
            setLoaded(true);
        }).catch((error)=>{
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        });        
        setLoaded(true);
      })

    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    setLoaded(true);

  }
}

const generateSubscriberID=()=>{
    const current_datetime = new Date();
    //const fName=profileDetails.firstName.trim().replace(/\s/g, "");
    //const tfName= fName.substring(0,2).toUpperCase();
    //const lName=profileDetails.lastName.trim().replace(/\s/g, "");
    //const tlName= lName.substring(0,2).toUpperCase();
    var tempUserId="";
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    var date_seconds = (current_datetime.getSeconds().toString());
    var seconds_value="";
    date_seconds.length===1 ?(seconds_value=seconds_value.toString().concat(0,date_seconds)):(seconds_value=seconds_value);
    const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value,seconds_value);
    return accountNo;
}

const resetValues=()=>{
    const todayDate= generateDate();
    const adminid= generateSubscriberID();
    setAdminDetails({...adminDetails,
        adminID:adminid, 
        registeredDate:todayDate
    });
  }

const handleCreateAccount=async(event)=>{
    //console.log("adminDetails : ", adminDetails);
    event.preventDefault();
    try{
      var tphone="";
      if(adminDetails.areaCode!==""&& adminDetails.adminPhoneNo!==""){
      tphone=adminDetails.areaCode.toString()+adminDetails.adminPhoneNo.toString();
      }
      if(adminDetails.adminName===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Name is mandatory."
      });        
      }else if(adminDetails.regDate===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Registration Date is mandatory."
      });        
      }else if(!adminDetails.registeredDate.match(dobRegex)){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Registration Date is not in proper format."
      });        
      }else if(adminDetails.adminEmailID===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Email Address is mandatory."
      });        
      }else if(!adminDetails.adminEmailID.match(validRegex)){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Email address is not in right format!"
      }); 
      }else if(!tphone.match(phoneRegex)){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Area Code and Phone Number is not in right format!"
      }); 
      }else if(adminDetails.areaCode==="" || adminDetails.adminPhoneNo===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Area Code and Mobile Number is mandatory."
      });        
      }else{
          setLoaded(false);
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken; 
          const todayDate=await generateDate();
          const clientID=loggedUser.familyName;
          const {adminName, adminID, adminEmailID, title, registeredDate, areaCode, adminPhoneNo, adminStatus, chruchRole} = adminDetails; 
          const params={
            clientID:clientID,
            adminID:adminID,
            adminEmailID:adminEmailID,
            adminName:adminName,
            title:title,
            registeredDate:registeredDate,
            areaCode:areaCode,
            adminPhoneNo:adminPhoneNo,
            chruchName:loggedUser.familyName,
            chruchRole:chruchRole,
            adminStatus:adminStatus,
            //chruchName:'SMC-MDU',
            updatedBy:loggedUser.name,
            updatedOn:todayDate
          }
          
        //console.log("Params : ", params);
        //await axios.get(`${config.api.invokeURL}/dashboard/reports/fee-batchwise?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
          await axios.post(`${config.api.invokeUrl}/dashboard/adminform`,params, {  
          headers: {
            Authorization: access_token,
            'x-api-key':config.api.key
          }
        }).then(async(response) => {
         //console.log("Response : ", response);
         if(response.status===215){
          setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: response.data
          });  
          }else if(response.status===201){
          //await fetchCurrentYearDetails();
          if(newImage){
              var uploadStatus="Failed";
              uploadStatus= await postOnS3(adminID);  
            }    

            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: "Admin Account Created Successfully!"
          });  
          }
          setLoaded(true)
      }).catch((error)=>{
       // console.log("Error : ", error);
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      });        
      setLoaded(true);
      window.scrollTo(0,0);
    })
  }
    }catch(error){
      console.log("Error : ", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    window.scrollTo(0,0);
    }
    window.scrollTo(0,0);
  }

  const postOnS3 = async(fileName)=>{
    try{
        //  console.log("NewImage : ", newImage);
      const newFileName=fileName+".jpeg";
      //console.log("newfilename :", newFileName);
      
      var file = newImage;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
     // console.log("New file : ", newFile);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;   
      const clientID=loggedUser.familyName;
      const bucketName=config.s3.bucketName+"/"+clientID+"/admins/"+fileName;
      await axios.get(`${config.api.invokeUrl}/dashboard/profile_avatar/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}`,{
     // await axios.get(`${config.api.invokeURL}/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}&keyName=${newKeyName}`,{
           headers:{
               Authorization: access_token,
               'x-api-key': config.api.key
           }
       }).then(async(response)=>{
          // console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
           //  console.log("Res : ", res);
             if(res.status===200){
              setNewProfileImage(newFileName);
             }
        }).catch((error)=>{
            //console.log("error1:", error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            return "Failed";
         // setDispMsg(error.message);
         // hideLoader();
       })
    
    }catch(error){
    //  console.log("error1:", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    return "Failed";
    }
  return "Success";
  }

  return (
    <div>
    <Helmet>
      <title>Admin Console - Admin Office - {churchMaster.chruchFullName}</title>
      <meta name="description" content="Admin Console - Church Admin Office" />
      <meta name="keywords" content="Admin Console - Church Admin Office" /> 
    </Helmet>

          <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>
    <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Accounts</Breadcrumb.Item>
            <Breadcrumb.Item active>Admin</Breadcrumb.Item>
          </Breadcrumb>
          <div >
      <Card border="light" className="bg-white shadow-sm mb-4">
      {profileImage && loaded && <ProfileImage profileImage={profileImage} viewOnly={viewOnly} setNewImage={setNewImage}/>}

      <Card.Body>
        <Form>
        
        <Row>
            <Col md={2} className="mb-3">
              <Form.Group id="title">
                <Form.Label>Title</Form.Label>
                <Form.Select value={adminDetails.title}
                disabled={viewOnly}
                onChange={(event)=>{setAdminDetails({...adminDetails, title:event.target.value})}}
               >
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Sr">Sr</option>
                  <option value="Br">Br</option>
                  <option value="Fr">Fr</option>
                  <option value="Other">other</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={10} className="mb-3">
              <Form.Group id="fullname">
                <Form.Label>Full Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter Full Name" value={adminDetails.adminName}
                  disabled={viewOnly}
                  onChange={(event)=>{setAdminDetails({...adminDetails, adminName:(event.target.value).toUpperCase()})}}  
               />
              </Form.Group>
            </Col>
          </Row> 
          <Row>
            <Col sm={4} className="mb-3">
              <Form.Group id="dateregistered">
                <Form.Label>Date Registered</Form.Label>
                <Form.Control required type="date" value={adminDetails.registeredDate}
                    disabled={viewOnly}
                    onChange={(event)=>{setAdminDetails({...adminDetails, registeredDate:event.target.value})}}
                />
              </Form.Group>
            </Col>
            <Col sm={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Status</Form.Label>
                <Form.Select value={adminDetails.adminStatus}
                        disabled={viewOnly}
                        onChange={(event)=>{setAdminDetails({...adminDetails, adminStatus:event.target.value})}}>
                    <option value="Active">Active</option>
                    <option value="In-Active">In-Active</option>
            </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="category">
                <Form.Label>Role</Form.Label>
                <Form.Select value={adminDetails.chruchRole}
                        disabled={viewOnly}
                        onChange={(event)=>{setAdminDetails({...adminDetails, chruchRole:event.target.value})}}>
                    <option value="Admin">Admin</option>
                    <option value="Super Admin">Super Admin</option>
            </Form.Select>              
            </Form.Group>
            </Col>

          </Row>
                  
          <Row>
          <Col md={6} className="mb-3">
              <Form.Group id="email">
                <Form.Label>Email Address</Form.Label>
                <Form.Control required type="email" value={adminDetails.adminEmailID}
                    disabled={viewOnly}
                    onChange={(event)=>{setAdminDetails({...adminDetails, adminEmailID:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col>
            <Form.Label>Contact Number</Form.Label>
            <Row>
              <Col sm={4} className="mb-3">
              <Form.Group id="areanumber">
                
                <Input type="select" name="areacode"
                                  value={adminDetails.areaCode} 
                                  disabled={viewOnly}
                                  onChange={(event)=>{setAdminDetails({...adminDetails, areaCode:event.target.value})}}
                                >
                                  {phoneCode.map(item => {
                                return (<option key={item.Code+item.Country} value={item.Code}>{item.Code}</option>);
                              })}
                  </Input>
                 </Form.Group>
            </Col>
             <Col sm={8} className="mb-3">
              <Form.Group id="phone">
                <Form.Control required type="number" placeholder="Enter your contact number" 
                                disabled={viewOnly}
                                value={adminDetails.adminPhoneNo} onChange={(event)=>{setAdminDetails({...adminDetails, adminPhoneNo:event.target.value})}}
                />
              </Form.Group>
            </Col>
            </Row>
            </Col>
            
            
          </Row>
          {/*<Row>
          <Col sm={6} className="mb-3">
              <Form.Group id="hname">
                <Form.Label>Chruch Name</Form.Label>
                <Form.Control required type="text" value={adminDetails.chruchFullName}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setAdminDetails({...adminDetails, chruchFullName:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group id="hname">
                <Form.Label>Chruch Code</Form.Label>
                <Form.Control required type="text" value={adminDetails.chruchCode}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setAdminDetails({...adminDetails, chruchCode:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
          </Row>
          <Row>
              <Form.Group className="mb-2">
                <Form.Label>Address</Form.Label>
                <Form.Control required as="textarea"  rows={3} placeholder="Enter Full Address" value={adminDetails.chruchAddress}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setAdminDetails({...adminDetails, chruchAddress:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
          </Row>*/}
         
</Form>
</Card.Body>
{viewOnly ? null :<Button variant="primary" type="submit" onClick={event=>handleCreateAccount(event)}>Submit</Button>}
</Card>
</div>
        </div>
    </div>
  )
}
