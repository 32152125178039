import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faHome, faSearch, faEllipsisH, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Table } from '@themesberg/react-bootstrap';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert, Label, FormGroup, Input, Card  } from "reactstrap";
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import axios from "axios";
import ReactToPrint from "react-to-print";
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import { cardStatusData, downloadCardHeaders, anbiyamData } from "../../ProData";
import {Helmet} from 'react-helmet-async';
import FamilyCardForm from "../registration/FamilyCardForm";
//import SubscriberForm from "./SubscriberForm";
//import PaymentForm from "../payment/PaymentForm";

const config = require('../../config.json');

export default function AnbiyamReportMain() {
  const { loggedUser, alertClose, alertDetails, setAlertDetails, validateLogin, familyCardFormType, setFamilyCardFormType, setCardDetails, displayMembers, setDisplayMembers, churchMaster, setDisplaySacrements} = useContext(ProductContext);
  const csvLinkEl = React.createRef();
  //const [applicationDetails, setApplicationDetails]=useState([]);
  const [searchTopic, setSearchTopic] = useState('FamilyZone');
  const [searchValue, setSearchValue] = useState('');
  const [loaded, setLoaded] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [displayResults, setDisplayResults] = useState([]);
  const [familyDisplay, setFamilyDisplay] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [newCollapsed, setNewCollapsed] = useState(true);
  const componentRef = React.useRef(null);
  const componentModelRef = React.useRef(null);
  //const [isLabelViewChecked, setIsLabelViewChecked] = useState(false);
  //const [viewOption,setViewOption] = useState("All");
  //const [isActiveView, setIsActiveView] = useState(true);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      setSearchValue(anbiyamData[0].anbiyamName);
      //processBatchYear();
    }else{
    validateLogin();
    alertClose();
    }
    return()=>{
      alertClose();
      setDisplayMembers([]);
      setDisplaySacrements([]);
      setDisplayResults([]);
    }
   // console.log(batchYear);
  },[loggedUser])

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const reactToPrintContentModal = React.useCallback(() => {
    return componentModelRef.current;
  }, [componentModelRef.current]);

  const reactToPrintTriggerModal = React.useCallback(() => {
    return <Button   >Print</Button>;
  }, []);

  const handleApplicationView=async(event, item, action)=>{
    event.preventDefault();
    try{
      setLoaded(false);
      //console.log("Display Members : ", item);
      if(searchTopic==="FamilyID1"){
        //console.log("Display Members : ", item);
        setFamilyCardFormType(action);
        setCardDetails(item);
      }else{
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;  
          const searchTopic="FamilyID";
          const tsearchValue=item.familyID;
          const clientID=loggedUser.familyName;
          await axios.get(`${config.api.invokeUrl}/dashboard/family_card?fetchKey=${tsearchValue}&fetchOption=${searchTopic}&clientID=${clientID}`, {
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
        //console.log("response : ", response);
        if(response.data.length>0)
        {
            //setDisplayResults(response.data[0]);
            if(searchTopic==="FamilyID"){
              setDisplayMembers(response.data[1]);
              setDisplaySacrements(response.data[2]);
            }
            setCardDetails(item);
            setFamilyCardFormType(action);
        }
        setAlertDetails({
          ...alertDetails,
          style:"success",
          open:true,
          message: response.data.length + " - Family card records found!"
      });  
        setLoaded(true);
    }).catch((error)=>{
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
  })
  }
    toggle();
    setLoaded(true);
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    }
  }

  const handlePaymentForm=(event, item, action)=>{
    event.preventDefault();
    try{
        setFamilyCardFormType(action);
      setCardDetails(item);
      newToggle();
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    }

  }

  const handleDownload =async(event)=>{
    event.preventDefault();
    csvLinkEl.current.link.click();
  }

  const toggle = () => {
    //   console.log("clicked");
         setCollapsed(!collapsed);
  }

  const newToggle = () => {
  //   console.log("clicked");
        setNewCollapsed(!newCollapsed);
  }

  const handleSearch=async()=>{
    //event.preventDefault();
    try{
      if(searchValue===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Search Value is empty Enter the search input and then hit Search button!"
      });  
      }else{
        setLoaded(false);
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;  
        var tsearchValue=searchValue
        if(searchTopic==="SubscriberName"){
          tsearchValue=searchValue.toUpperCase();
          setSearchValue(tsearchValue);
        }
        const clientID=loggedUser.familyName;
        await axios.get(`${config.api.invokeUrl}/dashboard/reports/anbiyam?fetchKey=${tsearchValue}&fetchOption=${searchTopic}&clientID=${clientID}`, {
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
        console.log("response : ", response);
        if(response.data.length>0)
        {
            setDisplayResults(response.data);
            //sortValues(response.data);
        }
        setAlertDetails({
          ...alertDetails,
          style:"success",
          open:true,
          message: response.data.length + " - Family card records found!"
      });  
        setLoaded(true);
    }).catch((error)=>{
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
  })
}
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    }
  }

     const sortValues=(tempData)=>{
        var sortedOptionArray = tempData.sort((a, b) => {return a.familyID - b.familyID;});
        setDisplayResults(sortedOptionArray);

        /*setCourseOptionAvailable(sortedOptionArray);
        setOptedCourse({...optedCourse,
          courseName:sortedcourseArray[0].courseName,
          courseOption:sortedOptionArray[0].courseOption
        });*/
    }


  /*const processResults=(rawData, type, activeView)=>{
    //const rawData=searchResults;
    var tempDisplayResults=[];
    if(type==="Email"){
      const filteredData=rawData.filter(item=>item.emailID!=="NA");
      tempDisplayResults=filteredData;
      //setDisplayResults(filteredData);
      setAlertDetails({
        ...alertDetails,
        style:"success",
        open:true,
        message: filteredData.length + " - Subscriber's records Filtered by Email"
    });  

      //console.log("filtered data : ", filteredData);
    }else if(type==="No Email"){
      const filteredData=rawData.filter(item=>item.emailID==="NA");
      tempDisplayResults=filteredData;
      //setDisplayResults(filteredData);
      setAlertDetails({
        ...alertDetails,
        style:"success",
        open:true,
        message: filteredData.length + " - Subscriber's records Filtered by No Email Address!"
    });  
      //console.log("filtered data : ", filteredData);
    }else{
      tempDisplayResults=rawData;
      //setDisplayResults(rawData);
      setAlertDetails({
        ...alertDetails,
        style:"success",
        open:true,
        message: rawData.length + " - Subscriber's records!"
    });  
    }
    if(activeView){
      const filteredData=tempDisplayResults.filter(item=>item.subStatus==="Active");
      tempDisplayResults=filteredData;
      //setDisplayResults(filteredData);
      setAlertDetails({
        ...alertDetails,
        style:"success",
        open:true,
        message: filteredData.length + " - Subscriber's records Filtered by Active Accounts"
      });  
    }
    setDisplayResults(tempDisplayResults);
  }*/

 /* const handleLabelViewCheck =()=>{
    //event.preventDefault();
    setIsLabelViewChecked(!isLabelViewChecked);
  }

  const handleViewOptionChange=async(type)=>{
    await processResults(searchResults, type, isActiveView);
    setViewOption(type);

  }

  const handleActiveSwitchChange=async()=>{
    await processResults(searchResults, viewOption, !isActiveView);
    setIsActiveView(!isActiveView);
  }*/

const handleSearchTopicSelection=(event)=>{
  event.preventDefault();
  setSearchTopic(event.target.value);
  if(event.target.value==="FamilyStatus"){
    setSearchValue("Active");
  }else if(event.target.value==="FamilyZone"){
    //console.log("anbiyam : ", churchMaster.chruchAnbiyam[0].anbiyamName);
    setSearchValue(churchMaster.chruchAnbiyam[0].anbiyamName);
  }else{
    setSearchValue();
  }

}

  return (
<>
    <Helmet>
      <title>Reports - Church Office - {churchMaster.chruchFullName}</title>
      <meta name="description" content="Reports - Church Admin Office" />
      <meta name="keywords" content="Reports - Church Admin Office" /> 
    </Helmet>

        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
        {/*View*/}
        <div >
          <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%', maxWidth:'800px'}} >
          <div  ref={componentModelRef}>
             <ModalHeader  toggle={toggle}>
               Registration Form - {familyCardFormType}
                    </ModalHeader>
                    <ModalBody >    
                        <FamilyCardForm handleSearch={handleSearch} toggle={toggle} /> 
                    </ModalBody>
                    <ModalFooter>
            {' '}
            <ReactToPrint
        content={reactToPrintContentModal}
        documentTitle="AwesomeFileName"
        
        trigger={reactToPrintTriggerModal}
      />
            <Button onClick={toggle}>
                Close
            </Button>
            </ModalFooter>
          </div>
                   
        </Modal>
        </div>

        {/*Payment*/}
        {/*<Modal toggle={newToggle} isOpen={!newCollapsed} style={{top:'10%', maxWidth:'800px'}}>
            <ModalHeader toggle={newToggle}>
            Payment - Add
            </ModalHeader>
            <ModalBody>    
            <div>
            <PaymentForm formType={"New"} newToggle={newToggle}/> 
              </div>
            </ModalBody>
            <ModalFooter>
            {' '}
            <Button onClick={newToggle}>
                Close
            </Button>
            </ModalFooter>
          </Modal>*/}


      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Anbiyam</Breadcrumb.Item>
            <Breadcrumb.Item active>Reports</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Reprots on Anbiyam</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
            content={reactToPrintContent}
            documentTitle={searchValue}
            trigger={reactToPrintTrigger}
          />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadCardHeaders}
              filename="download.csv"
              data={searchResults}
              ref={csvLinkEl}
            />
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-start align-items-center">
        <Col md={4} className="mb-3">
              <Form.Group id="searchstud">               
                <Form.Select defaultValue="FamilyZone" value={searchTopic} onChange={(event)=>{handleSearchTopicSelection(event)}}>
                <option value="FamilyZone">Anbiyam</option>
                </Form.Select>
              </Form.Group>
            </Col>
          <Col  className="mb-3" md={8}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              {searchTopic==="FamilyStatus"?           
              <Form.Select value={searchValue} autoFocus
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                  {cardStatusData.map(item => {
                    return (
                      <option key={item.name} value={item.name}>{item.name}</option>
                      );
                  })}
            </Form.Select>:searchTopic==="FamilyAnbiyam"?           
              <Form.Select value={searchValue} autoFocus
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                  {churchMaster.chruchAnbiyam.map(item => {
                    return (
                      <option key={item.id} value={item.anbiyamName}>{item.anbiyamName}</option>
                      );
                  })}
            </Form.Select>:searchTopic==="FamilyZone"?           
              <Form.Select value={searchValue} autoFocus
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                  {anbiyamData.map(item => {
                    return (
                      <option key={item.id} value={item.anbiyamName}>{item.anbiyamName}</option>
                      );
                  })}
            </Form.Select>:
            <Form.Control type="text"  autoFocus placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>}
              <Button variant="primary" type="submit" onClick={(event)=>{handleSearch()}}>Submit</Button>
            </InputGroup>
          </Col>
        </Row>
      </div>

     <Table ref={componentRef} hover responsive style={{padding:"0px !important"}}>
            <tbody >
            {displayResults.map((item, index)=>
            <>
              <tr style={{backgroundColor:"#31525B", color:"white"}}>
                <th>#</th>
                <th>Family Card ID - Family Head</th>
                <th>Members</th>
                <th>Anbiyam</th>
                <th>Subscription Paid</th>
                <th>Status</th>
              </tr>
            <tr style={{backgroundColor:"#31525B", color:"white"}} key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.familyID} - {item.familyHead}</td>
            <td>{item.familyMembers}</td>
            <td>{item.familyAnbiyam}</td>
            <td>{item.lastSubscriptionDate}</td>
            <td>{item.cardStatus==="Cancelled"?<p style={{backgroundColor:"blue", borderRadius:"10px", textAlign:"center", color:"white"}}>Cancelled</p>:
            item.cardStatus==="Active"?<p style={{backgroundColor:"green", borderRadius:"10px", textAlign:"center", color:"white"}}>Active</p>:
            <p style={{backgroundColor:"red", borderRadius:"10px", textAlign:"center", color:"white"}}>Expired</p>}</td>
           {/*<td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "View")}}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "Edit")}}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit Details
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handlePaymentForm(event, item, "Add")}}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Add Payment
              </Dropdown.Item>
            </Dropdown.Menu>
           </Dropdown>
            </td>*/}
           </tr>
           {item.family.length >0 && <tr style={{backgroundColor:"#b3dee5", color:"brown"}}>
          
           <th>#</th>
           <th>Name</th>
           <th>Relation</th>
           <th>Gender</th>
           <th>Profession</th>
           <th>Phone No</th>
           </tr>}
           {item.family.length >0 && item.family.map((member, i)=><tr key={i} style={{backgroundColor:"#b3dee5", color:"brown", border:"2px solid white"}}>
           
            <th scope="row">{i+1}</th>
            <td>{member.memberName}</td>
            <td>{member.relationship}</td>
            <td>{member.gender}</td>
            <td>{member.profession}</td>
            <td>{member.phoneNo}</td>
            </tr>
          
          )}  <tr style={{color:"white"}}>
            //
            </tr>
           </>)}
          
           </tbody>
      </Table>
    </>
  )
}
