import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
//import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

//auth
import Signin from './auth/Signin';
//import Signup from './auth/Signup';
import ForgotPassword from './auth/ForgotPassword';
import ResetPassword from './auth/ResetPassword';
import NotFoundPage from './auth/NotFound';
import ServerError from "./auth/ServerError";
 
//import ImageViewer from './ImageEditor/ImageViewer';
import AccountMgmt from './subscription/AccountMgmt';
//import SearchPage from './subscription/SearchPage';
import ManageSubscription from './subscription/ManageSubscription';
import SubscriberForm from './subscription/SubscriberForm';
import PaymentSearch from './payment/PaymentSearch';
import WelcomeDash from './dashboard/WelcomeDash';
//import MyProfile from './auth/MyProfile';
import InsightsOverview from './insights/InsightsOverview';
//import ManagePDF from './pdfJournal/ManagePDF';
import ViewPDF from './pdfJournal/ViewPDF';
//import PaymentForm from './payment/PaymentForm';
import AddPayments from './payment/AddPayments';
import AddPDF from './pdfJournal/AddPDF';
//import RegistrationForm from './registration/RegistrationForm';
import CardSearch from './search/CardSearch';
import MemberSearch from './search/MemberSearch';
import SacrementSearch from './search/SacrementSearch';
import FamilyCardForm from './registration/FamilyCardForm';
import AdminForm from './subscription/AdminForm';
import ListAdmins from './subscription/ListAdmins';
import SacrementInsights from './insights/SacrementInsights';
import MemberInsights from './insights/MemberInsights';
import AnbiyamReports from './reports/AnbiyamReports';
import AnbiyamReportMain from './reports/AnbiyamReportMain';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  /*const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }*/

  //const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

 /* const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }*/

  return (
    <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content">
          <Navbar />
          <Component {...props} />
         {/*} <Footer toggleSettings={toggleSettings} showSettings={showSettings} />*/}
        </main>
      </>
    )}
    />
  );
};

export default () => (
  <Switch>

    {/* auth */}
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <RouteWithSidebar exact path={Routes.ResetPassword.path} component={ResetPassword} />
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

    {/* pages */}
    <RouteWithSidebar exact path={Routes.Presentation.path} component={WelcomeDash} />
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={WelcomeDash} />
    <RouteWithSidebar exact path={Routes.MyProfile.path} component={AdminForm} />
    <RouteWithSidebar exact path={Routes.AdminForm.path} component={ListAdmins} />

    {/*Registration*/}
    <RouteWithSidebar exact path={Routes.NewRegistration.path} component={FamilyCardForm} />

    {/*Search*/}
    <RouteWithSidebar exact path={Routes.Search.path} component={CardSearch} />
    <RouteWithSidebar exact path={Routes.MemberSearch.path} component={MemberSearch} />
    <RouteWithSidebar exact path={Routes.SacrementSearch.path} component={SacrementSearch} />
    <RouteWithSidebar exact path={Routes.AccountMgmt.path} component={AccountMgmt} />
    <RouteWithSidebar exact path={Routes.ManageSubscribers.path} component={ManageSubscription} />
    <RouteWithSidebar exact path={Routes.AddSubscriber.path} component={SubscriberForm} />

    {/*Search*/}
    <RouteWithSidebar exact path={Routes.AnbiyamReports.path} component={AnbiyamReportMain} />

    {/*Journal*/}
    {/*<RouteWithSidebar exact path={Routes.ManagePDF.path} component={ViewPDF} />
    <RouteWithSidebar exact path={Routes.AddPDF.path} component={AddPDF} />*/}

    {/*Payments*/}
    <RouteWithSidebar exact path={Routes.AddPayments.path} component={AddPayments} />
    <RouteWithSidebar exact path={Routes.PaymentsReport.path} component={PaymentSearch} />

    {/*Insights*/}
    <RouteWithSidebar exact path={Routes.CardInsights.path} component={InsightsOverview} />
    <RouteWithSidebar exact path={Routes.MemberInsights.path} component={MemberInsights} />
    <RouteWithSidebar exact path={Routes.SacrementInsights.path} component={SacrementInsights} />

    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
