import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faHome, faSearch, faEllipsisH, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Table, Image } from '@themesberg/react-bootstrap';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert } from "reactstrap";
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import axios from "axios";
import ReactToPrint from "react-to-print";
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import { downloadMemberHeaders, memberStatusData } from "../../ProData";
import {Helmet} from 'react-helmet-async';
//import FamilyCardForm from "../registration/FamilyCardForm";
import FamilyMembersForm from "../registration/FamilyMembersForm";
import FamilySacrementsForm from "../registration/FamilySacrementsForm";
//import SubscriberForm from "./SubscriberForm";
//import PaymentForm from "../payment/PaymentForm";

const config = require('../../config.json');

export default function MemberSearch() {
  const { loggedUser, alertClose, alertDetails, setAlertDetails, validateLogin, setFamilyMemberFormType, familyMemberFormType, setFamilyMembers, setSacrementsFormType, sacrementsFormType, setFamilySacrements, handleImageError, churchMaster} = useContext(ProductContext);
  const csvLinkEl = React.createRef();
  //const [applicationDetails, setApplicationDetails]=useState([]);
  const [searchTopic, setSearchTopic] = useState('MemberID');
  const [searchValue, setSearchValue] = useState('');
  const [loaded, setLoaded] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [displayResults, setDisplayResults] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [newCollapsed, setNewCollapsed] = useState(true);
  const componentRef = React.useRef(null);
  const componentModelRef = React.useRef(null);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      //processBatchYear();
    }else{
    validateLogin();
    alertClose();
    }
    return()=>{
      alertClose();
      //setDisplayMembers([]);
      //setDisplaySacrements([]);
      setDisplayResults([]);
    }
   // console.log(batchYear);
  },[loggedUser])

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const reactToPrintContentModal = React.useCallback(() => {
    return componentModelRef.current;
  }, [componentModelRef.current]);

  const reactToPrintTriggerModal = React.useCallback(() => {
    return <Button   >Print</Button>;
  }, []);

  const handleApplicationView=(event, item, action)=>{
    event.preventDefault();
    try{
        setFamilyMemberFormType(action);
        setFamilyMembers(item);
      toggle();
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    }
  }

  const handleSacramentView=async(event, item, action)=>{
    event.preventDefault();
    try{
        setLoaded(false);
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;  
        const searchValue=item.memberID;
        const searchTopic="MemberID";
        const clientID=loggedUser.familyName;
      await axios.get(`${config.api.invokeUrl}/dashboard/family_sacrements?fetchKey=${searchValue}&fetchOption=${searchTopic}&clientID=${clientID}`, {
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
        //console.log("response : ", response);
        if(response.data.length>0)
        {
            setSacrementsFormType(action);
            setFamilySacrements(response.data[0]);
            newToggle();
        }
        setAlertDetails({
          ...alertDetails,
          style:"success",
          open:true,
          message: response.data.length + " - Family card records found!"
      });  
        setLoaded(true);

    }).catch((error)=>{
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
  })


    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    }

  }

  const handleDownload =async(event)=>{
    event.preventDefault();
    csvLinkEl.current.link.click();
  }

  const toggle = () => {
    //   console.log("clicked");
         setCollapsed(!collapsed);
  }

  const newToggle = () => {
  //   console.log("clicked");
        setNewCollapsed(!newCollapsed);
  }

  const handleSearch=async()=>{
    //event.preventDefault();
    try{
      if(searchValue===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Search Value is empty Enter the search input and then hit Search button!"
      });  
      }else{
        setLoaded(false);
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;  
        var tsearchValue=searchValue
        if(searchTopic==="MemberName"){
          tsearchValue=searchValue.toUpperCase();
          setSearchValue(tsearchValue);
        }
        const clientID=loggedUser.familyName;
      await axios.get(`${config.api.invokeUrl}/dashboard/family_members?fetchKey=${tsearchValue}&fetchOption=${searchTopic}&clientID=${clientID}`, {
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
        //console.log("response : ", response);
        if(response.data.length>0)
        {
            //setSearchResults(response.data);
            const tempData=response.data;
            const result = tempData.sort((a, b) =>a.memberID.localeCompare(b.memberID));
            setDisplayResults(result);
            //processResults(response.data, viewOption, isActiveView);
        }
        setAlertDetails({
          ...alertDetails,
          style:"success",
          open:true,
          message: response.data.length + " - Family card records found!"
      });  
        setLoaded(true);

    }).catch((error)=>{
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
  })
}
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    }
  }

  const processResults=(rawData, type, activeView)=>{
    //const rawData=searchResults;
    var tempDisplayResults=[];
    if(type==="Email"){
      const filteredData=rawData.filter(item=>item.emailID!=="NA");
      tempDisplayResults=filteredData;
      //setDisplayResults(filteredData);
      setAlertDetails({
        ...alertDetails,
        style:"success",
        open:true,
        message: filteredData.length + " - Subscriber's records Filtered by Email"
    });  

      //console.log("filtered data : ", filteredData);
    }else if(type==="No Email"){
      const filteredData=rawData.filter(item=>item.emailID==="NA");
      tempDisplayResults=filteredData;
      //setDisplayResults(filteredData);
      setAlertDetails({
        ...alertDetails,
        style:"success",
        open:true,
        message: filteredData.length + " - Subscriber's records Filtered by No Email Address!"
    });  
      //console.log("filtered data : ", filteredData);
    }else{
      tempDisplayResults=rawData;
      //setDisplayResults(rawData);
      setAlertDetails({
        ...alertDetails,
        style:"success",
        open:true,
        message: rawData.length + " - Subscriber's records!"
    });  
    }
    if(activeView){
      const filteredData=tempDisplayResults.filter(item=>item.subStatus==="Active");
      tempDisplayResults=filteredData;
      //setDisplayResults(filteredData);
      setAlertDetails({
        ...alertDetails,
        style:"success",
        open:true,
        message: filteredData.length + " - Subscriber's records Filtered by Active Accounts"
      });  
    }
    setDisplayResults(tempDisplayResults);
  }

  const handleSearchTopicSelection=(event)=>{
    event.preventDefault();
    setSearchTopic(event.target.value);
    if(event.target.value==="MemberStatus"){
      setSearchValue("Active");
    }else{
      setSearchValue();
    }
  }
  

  return (
<>
    <Helmet>
      <title>Family Member Search - Admin Office - {churchMaster.chruchFullName}</title>
      <meta name="description" content="Family Member Search - Church Admin Office" />
      <meta name="keywords" content="Family Member Search - Church Admin Office" /> 
    </Helmet>

        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
        {/*View*/}
        <div >
          <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%', maxWidth:'800px'}} >
          <div  ref={componentModelRef}>
             <ModalHeader  toggle={toggle}>
               Registration Form - {familyMemberFormType}
                    </ModalHeader>
                    <ModalBody >    
                        <FamilyMembersForm handleSearch={handleSearch} toggle={toggle} /> 
                    </ModalBody>
                    <ModalFooter>
            {' '}
            <ReactToPrint
        content={reactToPrintContentModal}
        documentTitle="AwesomeFileName"
        
        trigger={reactToPrintTriggerModal}
      />
            <Button onClick={toggle}>
                Close
            </Button>
            </ModalFooter>
          </div>
                   
        </Modal>
        </div>

        {/*Sacrements*/}
        <Modal toggle={newToggle} isOpen={!newCollapsed} style={{top:'10%', maxWidth:'800px'}}>
            <ModalHeader toggle={newToggle}>
            Registration Form - {sacrementsFormType}
            </ModalHeader>
            <ModalBody>    
            <div>
            <FamilySacrementsForm formType={"New"} newToggle={newToggle}/> 
              </div>
            </ModalBody>
            <ModalFooter>
            {' '}
            <Button onClick={newToggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>


      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Family Member</Breadcrumb.Item>
            <Breadcrumb.Item active>Search</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Search Family Member</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
            content={reactToPrintContent}
            documentTitle="student"
            trigger={reactToPrintTrigger}
          />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadMemberHeaders}
              filename="download.csv"
              data={searchResults}
              ref={csvLinkEl}
            />
          </ButtonGroup>
        </div>
      </div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
      <div className="d-block mb-4 mb-md-0">
      </div>
        </div>

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
      <div className="d-block mb-4 mb-md-0">
      </div>
        </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-start align-items-center">
        <Col md={4} className="mb-3">
              <Form.Group id="searchstud">               
                <Form.Select defaultValue="MemberID" value={searchTopic} onChange={(event)=>{handleSearchTopicSelection(event)}}>
                <option value="MemberID">Family ID</option>
                <option value="MemberName">Name</option>
                <option value="MemberPhone">Phone No</option>
                <option value="MemberEmail">Email</option>
                </Form.Select>
              </Form.Group>
            </Col>
          <Col  className="mb-3" md={8}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              {searchTopic==="MemberStatus"?           
              <Form.Select value={searchValue} autoFocus
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                  {memberStatusData.map(item => {
                    return (
                      <option key={item.name} value={item.name}>{item.name}</option>
                      );
                  })}
            </Form.Select>:
            <Form.Control type="text"  autoFocus placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>}
              <Button variant="primary" type="submit" onClick={(event)=>{handleSearch()}}>Submit</Button>
            </InputGroup>
          </Col>
        </Row>
      </div>

     <Table ref={componentRef} hover responsive>
             <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Member ID</th>
                <th>Status</th>
                <th>Relationship</th>
                <th>Phone No</th>
                <th>Email Address</th>
               <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {displayResults.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td><div  className='d-flex justify-content-start'><Image src={`${config.s3.imageURL}${loggedUser.familyName}${"/"}${item.memberID.substring(0,10)}${"/"}${item.memberID}`+".jpeg"} className="user-avatar md-avatar rounded-circle "  onError={handleImageError}/><span style={{paddingLeft:'10px', paddingTop:'15px'}}>{item.title}.{item.memberName}</span></div></td>
            <td>{item.memberID}</td>
            <td>{item.memberStatus==="Cancelled"?<p style={{backgroundColor:"blue", borderRadius:"10px", textAlign:"center", color:"white"}}>Cancelled</p>:
            item.memberStatus==="Active"?<p style={{backgroundColor:"green", borderRadius:"10px", textAlign:"center", color:"white"}}>Active</p>:
            <p style={{backgroundColor:"red", borderRadius:"10px", textAlign:"center", color:"white"}}>Expired</p>}</td>
            <td>{item.relationship}</td>
            <td>{item.areaCode}-{item.phoneNo}</td>
            <td>{item.emailID}</td>
           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "View")}}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "Edit")}}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit Details
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleSacramentView(event, item, "View")}}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> View Sacrements
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleSacramentView(event, item, "Edit")}}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit Sacrements
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
      </Table>
    </>
  )
}
