import React, {useContext} from 'react';
import { Button, ButtonGroup, Dropdown, Table, Image } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEllipsisH, faEdit } from '@fortawesome/free-solid-svg-icons';
//import ReactToPrint from "react-to-print";
import { ProductContext } from '../../ProductProvider';
//import FamilyMembersForm from './FamilyMembersForm';

const config = require('../../config.json');

export default function ListFamilyMembers(props) {

    const {toggleMemberForm, displayMembers, collapsed, familyCardFormType } = props;
    const { loggedUser, alertDetails, setAlertDetails, setFamilyMemberFormType, setFamilyMembers, handleImageError} = useContext(ProductContext);
    const componentModelRef = React.useRef(null);
    const componentRef = React.useRef(null);

    const reactToPrintContent = React.useCallback(() => {
      return componentRef.current;
    }, [componentRef.current]);
  
    const reactToPrintTrigger = React.useCallback(() => {
      return <Button variant="outline-primary"  size="sm">Print</Button>;
    }, []);
  
    const reactToPrintContentModal = React.useCallback(() => {
      return componentModelRef.current;
    }, [componentModelRef.current]);
  
    const reactToPrintTriggerModal = React.useCallback(() => {
      return <Button   >Print</Button>;
    }, []);  

    const handleNewProcessing=(event)=>{
        event.preventDefault();
        try{
            //console.log("Test");
            toggleMemberForm();
        }catch(error){

        }
    }
    
    const handleMembersView=(event, item, action)=>{
      event.preventDefault();
      try{
          setFamilyMemberFormType(action);
          setFamilyMembers(item);
          toggleMemberForm();
      }catch(error){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      });        
      }
    }
  

  return (
    <>
    {familyCardFormType==="View"?null:<Button variant="primary" type="submit" onClick={(event)=>{handleNewProcessing(event)}}>New</Button>}
    <div>Family Members</div>
    <Table hover responsive>
             <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>RelationShip</th>
                <th>Phone No</th>
                <th>emailID</th>
                <th>Status</th>
                {familyCardFormType==="View"?null:<th>Action</th>}
              </tr>
            </thead>
            <tbody>
            {displayMembers.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td><div className='d-flex justify-content-start'><Image src={`${config.s3.imageURL}${loggedUser.familyName}${"/"}${item.memberID.substring(0,10)}${"/"}${item.memberID}`+".jpeg"} className="user-avatar md-avatar rounded-circle "  onError={handleImageError}/><span style={{paddingLeft:'10px', paddingTop:'15px'}}>{item.title}. {item.memberName}</span></div></td>
            <td>{item.relationship}</td>
            <td>{item.areaCode}-{item.phoneNo}</td>
            <td>{item.emailID}</td>
            <td>{item.memberStatus}</td>
            {familyCardFormType==="View"?null:<td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={(event)=>{handleMembersView(event, item, "View")}}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleMembersView(event, item, "Edit")}}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit Details
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>}
            </tr>)}
            </tbody>
      </Table>
    </>
  )
}
