import React, { useState, useContext, useEffect } from 'react';
//import axios from "axios";
//import { Auth } from "aws-amplify";
import { ProductContext } from '../../ProductProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCamera } from "@fortawesome/free-solid-svg-icons";

const config = require('../../config.json');


export default function ProfileImage(props) {
    const { setNewMemberImage } = useContext(ProductContext)
    const inputRef = React.useRef();
    const triggerFileSelectPopup = () => inputRef.current.click();
    const { profileImage, viewOnly, setNewImage}=props;
    const [image, setImage]=useState(`${config.s3.imageURL}${profileImage}`)
    const [loaded, setLoaded]=useState(false);

useEffect(() => {
 // setImage(`${config.s3.imageURL}${profileImage}`);
  //setImage(`${profileImage}`);
  //console.log("profile image = ", image);
  
}, [])

/*const AddImagetoS3 =async(imageFile,tfileName) => {
  //console.log("Files selected : ",files);
  setDispMsg('');
  const timestamp=new Date().getTime();
  const fileName=tfileName+"-"+timestamp+".jpeg";
  console.log("filename : ", fileName);
  //const f=files[0];
  try{
      //getting presigned URL
  const session = await Auth.currentSession();
  const access_token=session.idToken.jwtToken;
  const bucketName=config.s3.bucketName;
  //const fileName=tfileName;
  //console.log("selected file : ", selectedImageFile['name']);
  //console.log("Image file : ", imageFile.name);
    //var f=imageFile;
    //console.log("initial file : ", f);
    
    var file = imageFile;
    var blob = file.slice(0, file.size, 'image/jpeg'); 
    const newFile = new File([blob], fileName, {type: 'image/jpeg'});
   // console.log("after update : ", newFile);
  await axios.get(`${config.api.invokeUrl}/profile_avatar/presigned/uploader?fileName=${fileName}&bucketName=${bucketName}`,{
  //await axios.get(`${config.api.invokeUrl}/presigned/uploader?fileName=${fileName}&bucketName=${bucketName}`,{
      headers:{
          Authorization: access_token,
          'x-api-key': config.api.key
      }
  })
   .then(async(response)=>{
     //  console.log("response : ", response);
     //  console.log("selected file : ", newFile);
      await fetch(response.data.uploadURL, {
      method: 'PUT',
      headers:{
          "Content-Type":"image/jpeg"
      },
      body: newFile
      
    });
   // setUser({...user,profileImage:fileName});
    setDispMsg("Image Successfully Uploaded!");*/
    //console.log("filename : ", fileName);
   // f.remove();
  //  fetchGalleryImages();

    //console.log("Result : ",result);
    
      /*await axios.put(response.data.uploadURL,{body:f['file']},{
          headers:{
              "Content-Type":"image/jpeg"
          }
        }).then(()=>{
            //console.log("Result : ", res);
            setDispMsg("Image Successfully Uploaded!");
        }).catch((error)=>{
          setDispMsg("Error Occured - ", error.message);
        })*/
   /*}).catch((error)=>{
      setDispMsg("Error Occured - ", error.message);
   })
    
  }catch(error){
      setDispMsg("Error Occured - ", error.message);

  }
  return fileName;

   }*/

   const handleSelectFile = async(event)=>{
    event.preventDefault();
    //console.log("inside");
    if (event.target.files && event.target.files.length > 0) {
   //  console.log("Event : ", event.target.files[0]);
      setLoaded(false);
      await setNewMemberImage(event.target.files[0]);
      await setNewImage(event.target.files[0]);
      setImage(event.target.files[0]);
      var tprofile = document.getElementById("profile");
      tprofile.src=URL.createObjectURL(event.target.files[0]);
      setImage(tprofile.src);
      //console.log("newimage : ", newImage);
     // console.log("url = ", tprofile.src);
     setLoaded(true);
    }
 }

/*   const handleSelectFile = async(event)=>{
     event.preventDefault();
     console.log("Event : ", event.target.files[0]);
     try{
     if (event.target.files && event.target.files.length > 0) {
       //console.log("file selected");
       //const timestamp=new Date().getTime();
       //const newFileName=fileName+"-"+timestamp+".jpeg";
       const newFileName=fileName+".jpeg";
       //console.log("New file name : ", newFileName);
       const session = await Auth.currentSession();
       const access_token=session.idToken.jwtToken;
       const bucketName=config.s3.bucketName;
       var file = event.target.files[0];
       var blob = file.slice(0, file.size, 'image/jpeg'); 
       const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
       //console.log("New file : ", newFile);
       await axios.get(`${config.api.invokeUrl}/profile_avatar/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}`,{
            headers:{
                Authorization: access_token,
                'x-api-key': config.api.key
            }
        }).then(async(response)=>{
           const res=await fetch(response.data.uploadURL, { method: 'PUT',
            headers:{
                "Content-Type":"image/jpeg"
              },body: newFile});
              //console.log("Res : ", res);
              if(res.status===200){
               // await setUser({...user, profileImage:newFileName});
                  setProfileImage(newFileName);
                  var timestamp = new Date().getTime();     
                  var tprofile = document.getElementById("profile");
                  var imgsrc="";
                  imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                  //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                  tprofile.src=imgsrc;
                 // console.log("tprofle : ", tprofile.src);

              }
         }).catch((error)=>{
           setDispMsg(error.message);
        })
     }
    }catch(error){
      setDispMsg(error.message);
    }
   }*/

   const handleImageError=(e)=>{
    // console.log("Event : ", e);
    e.target.src = "https://s3.ap-south-1.amazonaws.com/dev.idntica.com/img/gallery/defaultAvatar.jpg"


   }


  /* const onSelectFile = async(event) => {
     const currentURL=config.s3.imageURL+profileImage;
     //console.log("Current URL : ", currentURL);
     //console.log("Event : ", event);
    if (event.target.files && event.target.files.length > 0) {
       // props.setSelectedImageFile(event.target.files[0]);
       //console.log("Files : ", event.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", async() => {
        setImage(reader.result);
        //console.log("Image : ", reader.result);
        const timestamp=new Date().getTime();
        const newFileName=fileName+"-"+timestamp+".jpeg";
        //console.log("filename : ", newFileName);
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        const bucketName=config.s3.bucketName;
        var file = event.target.files[0];
        var blob = file.slice(0, file.size, 'image/jpeg'); 
        const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
       // console.log("after update : ", newFile);
      await axios.get(`${config.api.invokeUrl}/profile_avatar/presigned/uploader?fileName=${fileName}&bucketName=${bucketName}`,{
      //await axios.get(`${config.api.invokeUrl}/presigned/uploader?fileName=${fileName}&bucketName=${bucketName}`,{
          headers:{
              Authorization: access_token,
              'x-api-key': config.api.key
          }
      })
       .then(async(response)=>{
         //  console.log("response : ", response);
         //  console.log("selected file : ", newFile);
          await fetch(response.data.uploadURL, {
          method: 'PUT',
          headers:{
              "Content-Type":"image/jpeg"
          },
          body: newFile
          
        });
       // setUser({...user,profileImage:fileName});
        setDispMsg("Image Successfully Uploaded!");
     
       }).catch((error)=>{
        // console.log("Error : ", error);
          setDispMsg("Error Occured - ", error.message);
       })*/
        
      
        /*const tfileName= await AddImagetoS3(event.target.files[0], fileName);
        console.log("tfileName : ",tfileName);
        await props.setUser({...user,
            profileImage:tfileName
        });*/
        //console.log("selected File : ", selectedFile);
    //  });
     /* const tfileName= await AddImagetoS3(event.target.files[0], fileName);
      var tprofile = document.getElementById("profile");     
      tprofile.src=`${config.s3.imageURL}${tfileName}`;
      console.log("tfileName : ",tfileName);*/
      /*await props.setUser({...user,
          profileImage:tfileName
      });*/

     // fetchMyDetails();
     // window.location.reload();
 /*   }
  };
  */
    return (
      <div style={{textAlign:"center"}}> 
      
       {profileImage && <img id="profile" src={image} 
            onError={handleImageError}
            alt="Profile" style={{width:"6rem", height:"6rem", borderRadius:"50%"}} />
       }
       {!viewOnly?<div> <input
          type="file"
          accept="image/*"
          ref={inputRef}
          onChange={(event)=>{handleSelectFile(event)}}
          style={{ display: "none" }}
        />
        
        <span style={{backgroundColor:'primary', width:'120px', height:'120px', borderRadius:"20%", position:'relative', left:'30px', top:'-25px', zIndex:"999"}}>
        <FontAwesomeIcon icon={faCamera} className="me-2" onClick={triggerFileSelectPopup} style={{ border:'2px solid white', borderRadius:'50%',color:'black', left:'-20px', top:'35px', fontSize:'20px', zIndex:'500', cursor:'pointer'}}/>
            </span>
            </div>:null}
        </div>
      
    )

}