import React, { useEffect, useState, useContext, useRef } from "react";
import { Col, Row, Card, Form,  Tab, Tabs } from 'react-bootstrap';
import ReactToPrint from "react-to-print";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup, Breadcrumb } from '@themesberg/react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../components/Preloader";
import { Auth } from "aws-amplify";
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert} from "reactstrap";
import {Helmet} from 'react-helmet-async';

//import FileAttachments from "../../components/attachments/FileAttachments";
//import ProfileImage from "../../components/manageimage/ProfileImage";
import { cardStatusData } from "../../ProData";
import axios from "axios";
import ListFamilyMembers from "./ListFamilyMembers";
import ListFamilySacrements from "./ListFamilySacrements";
import FamilyMembersForm from "./FamilyMembersForm";
import FamilySacrementsForm from "./FamilySacrementsForm";
import ProfileImage from "../profile/ProfileImage";

const config = require('../../config.json');
//const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;


export  default function FamilyCardForm(props) {
  const { handleSearch} = props;
  const { loggedUser, alertClose, alertDetails, setAlertDetails, generateDate, validateLogin, familyCardFormType, setFamilyCardFormType, cardDetails, setCardDetails, churchMaster, displayMembers, setDisplayMembers, displaySacrements, setDisplaySacrements, setNewImage } = useContext(ProductContext);
  const [loaded, setLoaded] = useState(false);
  const [viewOnly, setViewOnly] = useState(true);
  //const [subViewOnly, setSubViewOnly] = useState(true);
  const [tabValue, setTabValue]=useState("Members");
  const [collapsed, setCollapsed] = useState(true);
  const [collapsedSacrements, setCollapsedSacrements] = useState(true);
  //const [displayMembers, setDisplayMembers] = useState([]);
  //const [displaySacrements, setDisplaySacrements] = useState([]);
  const [viewFamilyDetails, setViewFamilyDetails] = useState(false);
  const [profileImage, setProfileImage] = useState('defaultAvatar.jpg');
  //const [newProfileImage, setNewProfileImage]=useState('defaultAvatar.jpg')
  const [stationName, setStationName] = useState(churchMaster.chruchAnbiyam);
  const [anbiyamName, setAnbiyamName] = useState(churchMaster.chruchAnbiyam);
  const componentRef = React.useRef(null);
  const inputTitle = useRef(null);
  const inputAddressCity=useRef(null);
  const inputAddressPincode=useRef(null);
  const inputAddressState=useRef(null);
  const inputAreaCode=useRef(null);
  const inputCategoryID=useRef(null);
  const inputContactNo=useRef(null);
  const inputCopies=useRef(null);
  const inputCountry=useRef(null);
  const inputHouseName=useRef(null);
  const inputAddressStreet=useRef(null);
  const inputEmailID =useRef(null);
  const inputRegDate=useRef(null);
  const inputEndDate=useRef(null);
  const inputStartDate=useRef(null);
  const inputSubStatus=useRef(null);
  const inputSubscriberName=useRef(null);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      if(familyCardFormType==="New"){
        setViewOnly(false);
        //resetValues();
        initializeValues();
        //generateID();
    }else if(familyCardFormType==="Edit"){
      initializeValues();
      setViewFamilyDetails(true);
      setViewOnly(false);
      if(cardDetails.memberID){
        const clientID=loggedUser.familyName;
        const memberID=cardDetails.memberID;
        const tempPic=clientID+"/"+memberID.substring(0,10)+"/"+memberID+".jpeg";
        setProfileImage(tempPic);  
        //console.log("Profile Pic : ", profileImage);
      }
    }else if(familyCardFormType==="View"){
      initializeValues();
      setViewOnly(true);
      setViewFamilyDetails(true);
      if(cardDetails.memberID){
        const clientID=loggedUser.familyName;
        const memberID=cardDetails.memberID;
        const tempPic=clientID+"/"+memberID.substring(0,10)+"/"+memberID+".jpeg";
        setProfileImage(tempPic);  
        //console.log("Profile Pic : ", profileImage);
      }
    }
    setLoaded(true);
  }else{
    resetValues();
    validateLogin();
  }
  //console.log("Members : ", displayMembers);
  //console.log("Sac : ", displaySacrements);
    return () => {
      if(familyCardFormType==="New"){
        setDisplayMembers([]);
        setDisplaySacrements([]);
        resetValues();
      }
        setFamilyCardFormType("New");
      }
  },[]) 

  const initializeValues=()=>{
    var uniqueArray = removeDuplicates("subStation");
    const anbiyamList=churchMaster.chruchAnbiyam;
    var sortedcourseArray = uniqueArray.sort((a, b) => {return a.stationID - b.stationID;});
    setStationName(sortedcourseArray);
    if(familyCardFormType==="New"){
      const defaultStation=sortedcourseArray[0].subStation;
      var result=anbiyamList.filter(obj=> obj.subStation == defaultStation);
      setAnbiyamName(result);
     //console.log("Courses Name : ", result);
     /*uniqueArray = removeDuplicates("chruchZone");
      var sortedOptionArray = uniqueArray.sort((a, b) => {return a.cat_id - b.cat_id;});
      setZoneName(sortedOptionArray);*/
      const todayDate= generateDate();
      setCardDetails({...cardDetails,
        familyID:'', registrationDate:todayDate,
        familyStation:sortedcourseArray[0].subStation,
        familyAnbiyam:result[0].anbiyamName,
        graveYardNo:'NA', lastSubscriptionDate:todayDate,
        familyAddress:'', cardStatus:'Active',
        familyHead:'', familyMembers:'0'
      });  
    }else{
      const defaultStation=cardDetails.familyStation;
      var result=anbiyamList.filter(obj=> obj.subStation == defaultStation);
      setAnbiyamName(result);
    }
  }

  const handleNewCard=(event)=>{
    event.preventDefault();
      alertClose();
      setViewOnly(false);
      initializeValues();
      setDisplayMembers([]);
      setDisplaySacrements([]);

  }

  const removeDuplicates=(tprops)=> {
    var newArray = [];
    var lookupObject  = {};
    const anbiyamList=churchMaster.chruchAnbiyam;
    for(var i in anbiyamList) {
       lookupObject[anbiyamList[i][tprops]] = anbiyamList[i];
    }

    for(i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
     return newArray;
}
  
  const handleEventKey=(event, item)=>{
    if(event.key === 'Enter'){
        if(item==="1"){
            inputSubscriberName.current.focus();
        }else if(item==="2"){
          inputRegDate.current.focus();
        }else if(item==="3"){
          inputSubStatus.current.focus();
        }else if(item==="4"){
          inputStartDate.current.focus();
        }else if(item==="5"){
          inputEndDate.current.focus();
        }else if(item==="6"){
          inputEmailID.current.focus();
        }else if(item==="7"){
          //console.log("event : ", event);
            inputAreaCode.current.focus();
        }else if(item==="8"){
          inputContactNo.current.focus();
        }else if(item==="9"){
          inputCategoryID.current.focus();
        }else if(item==="10"){
          inputCopies.current.focus();
        }else if(item==="11"){
          inputHouseName.current.focus();
        }else if(item==="12"){
          inputAddressStreet.current.focus();
        }else if(item==="13"){
          inputAddressCity.current.focus();
        }else if(item==="14"){
          inputAddressState.current.focus();
        }else if(item==="15"){
          inputAddressPincode.current.focus();
        }else if(item==="16"){
          inputCountry.current.focus();
        }
    }
}

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const handleSubcriberEdit=async(event)=>{
    event.preventDefault();
    try{
    alertClose();
    const validationResult=validateFields();
    if(validationResult==="passed"){
      setLoaded(false);
      const {familyID, registrationDate,familyStation, familyAnbiyam, dateCancelled, subscription, graveYardNo, comments, familyAddress, cardStatus} = cardDetails;
      const todayDate=await generateDate();
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      const clientID=loggedUser.familyName;
      const params={
        clientID:clientID, 
        familyID: familyID, 
        registrationDate:registrationDate,
        familyStation:familyStation,
        familyAnbiyam:familyAnbiyam,
        subscription:subscription, 
        graveYardNo:graveYardNo,
        //lastSubscriptionDate:lastSubscriptionDate, 
        familyAddress:familyAddress, 
        cardStatus:cardStatus, 
        dateCancelled:dateCancelled,
        comments:comments,
        updatedBy:loggedUser.username, 
        updatedOn:todayDate
      };
      console.log("Params : ",params);
      await axios.patch(`${config.api.invokeUrl}/dashboard/family_card`, params,{
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }})
        .then(async(response)=>{
          //toggle();
       await handleSearch();
          setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: familyID + " - Successfully Updated."
        }); 
        window.scrollTo(0,0);
        setLoaded(true);
        }).catch((error)=>{
         //console.log("error : ", error);
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: "Error Occured : " + error.message
          }); 
          window.scrollTo(0,0);
          setLoaded(true);
        })
        }
      }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    window.scrollTo(0,0);
    setLoaded(true);
    }
  }

  /*const generateSubscriberID=()=>{
    const current_datetime = new Date();
    //const fName=profileDetails.firstName.trim().replace(/\s/g, "");
    //const tfName= fName.substring(0,2).toUpperCase();
    //const lName=profileDetails.lastName.trim().replace(/\s/g, "");
    //const tlName= lName.substring(0,2).toUpperCase();
    var tempUserId="";
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    var date_seconds = (current_datetime.getSeconds().toString());
    var seconds_value="";
    date_seconds.length===1 ?(seconds_value=seconds_value.toString().concat(0,date_seconds)):(seconds_value=seconds_value);
    const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value,seconds_value);
    return accountNo;
}*/

  const handleNewFamilyCard=async(event)=>{
    event.preventDefault();
    try{
    //  console.log("value : ", emailCodeEntered, emailCodeReceived);
    alertClose();
    const validationResult=validateFields();
    if(validationResult==="passed"){

      setLoaded(false);
      const {familyID, registrationDate, familyStation, familyAnbiyam, graveYardNo, familyAddress, cardStatus, subscription, dateCancelled, comments} = cardDetails;

      //const clientID = "VJDEPTH";
      const todayDate=await generateDate();
      //const applicationStatus="Pending Approval";
      //const tcourseStatus="Pending Approval";
      //const tcoursePayment="Pending";   
      //const tapplicantName=firstName+ " "+middleName+ " "+lastName;    
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      const familyid=familyID;
      const clientID=loggedUser.familyName;
      const params = {
        clientID:clientID, 
        familyID:familyid, 
        registrationDate:registrationDate,
        familyStation:familyStation, 
        familyAnbiyam:familyAnbiyam,
        familyMembers:0,
        graveYardNo:graveYardNo, 
        subscription:subscription, 
        familyHead:'NA',
        lastSubscriptionDate:registrationDate, 
        familyAddress:familyAddress, 
        cardStatus:cardStatus, 
        dateCancelled:dateCancelled,
        comments:comments,
        updatedBy:loggedUser.username, 
        updatedOn:todayDate
      };

    //console.log("Params : ", params);
    await axios.post(`${config.api.invokeUrl}/dashboard/family_card`, params,{
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }})
        .then(async(response)=>{
          //console.log("response : ", response); 
          if(response.status===201){
            const familyid=JSON.parse(response.data);
            await setCardDetails({...cardDetails,
            familyID:familyid});
          setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: "Family ID - " + familyid + " - Successfully Registered."
        }); 
        setViewFamilyDetails(true);
        setFamilyCardFormType("New");
        setViewOnly(true);
        //window.scrollTo(0,0);
        //resetValues();
      }else if(response.status===251){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Duplicate Family ID - "+ familyid + " - Found. Correct or Refresh the Family     ID and Try Again."
      }); 
      window.scrollTo(0,0);      
      }
        setLoaded(true);
        }).catch((error)=>{
          console.log("error : ", error);
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: "Error Occured : " + error.message
          }); 
          window.scrollTo(0,0);
          setLoaded(true);
        })
        setLoaded(true);
    }
    }catch(error){
      console.log("error : ", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    window.scrollTo(0,0);
    setLoaded(true);
    }
  }

 /* const postOnS3 = async(fileName)=>{
    try{
        //  console.log("NewImage : ", newImage);
      const newFileName=fileName+".jpeg";
      //console.log("newfilename :", newFileName);
      
      var file = newImage;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
     // console.log("New file : ", newFile);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;   
      const bucketName=config.s3.bucketName;
      await axios.get(`${config.api.invokeUrl}/dashboard/profile_avatar/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}`,{
     // await axios.get(`${config.api.invokeURL}/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}&keyName=${newKeyName}`,{
           headers:{
               Authorization: access_token,
               'x-api-key': config.api.key
           }
       }).then(async(response)=>{
          // console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
           //  console.log("Res : ", res);
             if(res.status===200){
              setNewProfileImage(newFileName);
             }
        }).catch((error)=>{
            //console.log("error1:", error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            return "Failed";
         // setDispMsg(error.message);
         // hideLoader();
       })
    
    }catch(error){
    //  console.log("error1:", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    return "Failed";
    }
  return "Success";
  }
*/

  const resetValues=()=>{
    const todayDate= generateDate();
    //const familyid= generateSubscriberID();
    setCardDetails({...cardDetails,familyID:'', registrationDate:todayDate, familyStation:'', familyAnbiyam:'01.Valimai - வலிமை',graveYardNo:'NA', lastSubscriptionDate:todayDate, familyAddress:'', cardStatus:'Active', familyHead:'', familyMembers:'0'});
  }

  const validateFields=()=>{
    const {registrationDate,familyZone, familyAmbiyam,graveYardNo, lastSubscriptionDate, familyAddress, cardStatus} = cardDetails;
        var returnValue="passed";
        if(registrationDate===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Registration Date cannot be Empty"
      }); 
      returnValue="failed";
        }else  if(familyAmbiyam===""){
            setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: "Anbiyam details cannot be Empty"
        }); 
        returnValue="failed";
        }else  if(graveYardNo===""){
            setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: "Graveyard cannot be Empty"
        }); 
        returnValue="failed";
        }/*else  if(lastSubscriptionDate===""){
            setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: "Subscription Date cannot be Empty"
        }); 
        returnValue="failed";
        }*/else  if(familyAddress===""){
            setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: "Address cannot be Empty"
        }); 
        returnValue="failed";
        }else  if(cardStatus===""){
            setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: "Card Status cannot be Empty"
        }); 
        returnValue="failed";
        }else {
            returnValue="passed";
        }
        window.scrollTo(0,0);
        return returnValue;
    }

  /*const handleSubscriberIDEdit=(e)=>{
    e.preventDefault();
    setSubViewOnly(false);
  }

  const handleSubscriberIDRefresh=(e)=>{
    e.preventDefault();
    const familyid= generateSubscriberID();
    setCardDetails({...cardDetails,familyID:familyid});
  }

  const handleSubscriberIDClose=(e)=>{
    e.preventDefault();
    setSubViewOnly(true);
  }*/

  const toggle = () => {
       //console.log("clicked");
         setCollapsed(!collapsed);
  }

  const toggleSacrements = () => {
    //console.log("clicked");
      setCollapsedSacrements(!collapsedSacrements);
    }

  const handleStationSelection=(event)=>{
    const defaultStation=event.target.value;
    const anbiyamList=churchMaster.chruchAnbiyam;
    var result=anbiyamList.filter(obj=> obj.subStation == defaultStation);
    setAnbiyamName(result);
   //console.log("Courses Name : ", result);
   /*uniqueArray = removeDuplicates("chruchZone");
    var sortedOptionArray = uniqueArray.sort((a, b) => {return a.cat_id - b.cat_id;});
    setZoneName(sortedOptionArray);*/
    setCardDetails({...cardDetails,
      familyStation:defaultStation,
      familyAnbiyam:result[0].anbiyamName
    });

  }

  return (
    <div>
    <Helmet>
      <title>Family Card - Admin Office - {churchMaster.chruchFullName}</title>
      <meta name="description" content="Family Card - Church Admin Office" />
      <meta name="keywords" content="Family Card - Church Admin Office" /> 
    </Helmet>

        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>

        {/*MemberForm*/}
        <div >
          <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%', maxWidth:'800px'}} >
          <div >
             <ModalHeader  toggle={toggle}>
               Registration Form - {familyCardFormType}
                    </ModalHeader>
                    <ModalBody >    
                        <FamilyMembersForm displayMembers={displayMembers} setDisplayMembers={setDisplayMembers} displaySacrements={displaySacrements} setDisplaySacrements={setDisplaySacrements} toggleMemberForm={toggle} /> 
                    </ModalBody>
                    <ModalFooter>
            {' '}
           
            <Button onClick={toggle}>
                Close
            </Button>
            </ModalFooter>
          </div>
                   
        </Modal>
        </div>

         {/*SacrementsForm*/}
         <div >
          <Modal toggle={toggleSacrements} isOpen={!collapsedSacrements} style={{top:'10%', maxWidth:'800px'}} >
          <div >
             <ModalHeader  toggle={toggleSacrements}>
               Sacrements Form - {familyCardFormType}
                    </ModalHeader>
                    <ModalBody >    
                        <FamilySacrementsForm displaySacrements={displaySacrements} setDisplaySacrements={setDisplaySacrements} handleSearch={handleSearch}  toggleSacrementForm={toggleSacrements} /> 
                    </ModalBody>
                    <ModalFooter>
            {' '}
           
            <Button onClick={toggleSacrements}>
                Close
            </Button>
            </ModalFooter>
          </div>
                   
        </Modal>
        </div>

        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Registration</Breadcrumb.Item>
            <Breadcrumb.Item active>Family Card</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleNewCard(event)}}>New</Button>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="student"
        trigger={reactToPrintTrigger}
      />
          </ButtonGroup>
        </div>
        </div>
     
      <div ref={componentRef}>
      <Card border="light" className="bg-white shadow-sm mb-4">
      {profileImage && loaded && <ProfileImage profileImage={profileImage} viewOnly={true} setNewImage={setNewImage}/>}
      <p style={{textAlign:"center"}}>{cardDetails.familyID}</p>
      <p style={{textAlign:"center"}}>{cardDetails.familyHead}</p>
      <p style={{backgroundColor:"green", borderRadius:"10px", textAlign:"center", color:"white"}}>Family Members - {cardDetails.familyMembers}</p>

      <Card.Body>
        <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Registration Date</Form.Label>
                <Form.Control type="date"  autoFocus placeholder="Registration Date" value={cardDetails.registrationDate}
                  disabled={viewOnly} ref={inputSubscriberName} onKeyDown={event=>{handleEventKey(event,"2")}} 
                  onChange={(event)=>{setCardDetails({...cardDetails, registrationDate:(event.target.value).toUpperCase()})}}  
               />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="category">
                <Form.Label>Status</Form.Label>
                <Form.Select value={cardDetails.cardStatus}
                        disabled={viewOnly} ref={inputCategoryID} onKeyDown={event=>{handleEventKey(event,"10")}} 
                        onChange={(event)=>{setCardDetails({...cardDetails, cardStatus:event.target.value})}}>
              {cardStatusData.map(item => {
                  return (
                  <option key={item.name} value={item.name}>{item.name}</option>
                  );})}
            </Form.Select>              
            </Form.Group>
            </Col>                  
          </Row> 
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="station">
                <Form.Label>Station</Form.Label>
                <Form.Select value={cardDetails.familyStation} 
                  disabled={viewOnly} ref={inputRegDate} onKeyDown={event=>{handleEventKey(event,"3")}} 
                  onChange={(event)=>{handleStationSelection(event)}}
                  //onChange={(event)=>{setCardDetails({...cardDetails, familyStation:event.target.value})}}
                  >
                    {stationName.map(item => {
                    return (
                    <option key={item.id} value={item.subStation}>{item.subStation}</option>
                    );
                })}
                </Form.Select>
              </Form.Group>
            </Col>
            
            <Col sm={6} className="mb-3">
              <Form.Group id="hname">
                <Form.Label>Anbiyam</Form.Label>
                <Form.Select value={cardDetails.familyAnbiyam} 
                  disabled={viewOnly} ref={inputRegDate} onKeyDown={event=>{handleEventKey(event,"3")}} 
                  onChange={(event)=>{setCardDetails({...cardDetails, familyAnbiyam:event.target.value})}}
                  >
                    {anbiyamName.map(item => {
                    return (
                    <option key={item.id} value={item.anbiyamName}>{item.anbiyamName}</option>
                    );
                })}
                </Form.Select>
              </Form.Group>
            </Col>
              
          </Row>
          <Row>
            <Col sm={4} className="mb-3">
              <Form.Group id="hname">
                <Form.Label>Monthly Subscription</Form.Label>
                <Form.Control required type="number" value={cardDetails.subscription} placeholder="Enter Monthly Subscription amount..."
                  disabled={viewOnly} ref={inputStartDate} onKeyDown={event=>{handleEventKey(event,"5")}} 
                  onChange={(event)=>{setCardDetails({...cardDetails, subscription:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={4} className="mb-3">
              <Form.Group id="hname">
                <Form.Label>Graveyard</Form.Label>
                <Form.Control required type="text" value={cardDetails.graveYardNo} placeholder="Enter Graveyard..."
                  disabled={viewOnly} ref={inputStartDate} onKeyDown={event=>{handleEventKey(event,"5")}} 
                  onChange={(event)=>{setCardDetails({...cardDetails, graveYardNo:event.target.value})}}
                  />
              </Form.Group>
            </Col>
           {familyCardFormType==="New"?null: <Col sm={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Last Subscription Paid</Form.Label>
                <Form.Control required type="date"  value={cardDetails.lastSubscriptionDate}
                  disabled ref={inputEndDate} onKeyDown={event=>{handleEventKey(event,"6")}} 
                  onChange={(event)=>{setCardDetails({...cardDetails, lastSubscriptionDate:event.target.value})}}
                  />
              </Form.Group>
              </Col>}
          </Row>   
          {cardDetails.cardStatus==="Active"?null: <Row>
          <Col sm={4} className="mb-3">
              <Form.Group id="hname">
                <Form.Label>Transferred / Cancelled Date</Form.Label>
                <Form.Control required type="date" value={cardDetails.dateCancelled}
                  disabled={viewOnly} ref={inputStartDate} onKeyDown={event=>{handleEventKey(event,"5")}} 
                  onChange={(event)=>{setCardDetails({...cardDetails, dateCancelled:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={8} className="mb-3">
              <Form.Group id="hname">
                <Form.Label>Comments</Form.Label>
                <Form.Control required type="text" value={cardDetails.comments} placeholder="Enter remarks..."
                  disabled={viewOnly} ref={inputStartDate} onKeyDown={event=>{handleEventKey(event,"5")}} 
                  onChange={(event)=>{setCardDetails({...cardDetails, comments:event.target.value})}}
                  />
              </Form.Group>
            </Col>
          </Row>   } 
          <Row>
          <Col md={12} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Address</Form.Label>
                <Form.Control as="textarea"  rows={3} value={cardDetails.familyAddress} placeholder="Enter address here..."
                  disabled={viewOnly} ref={inputEmailID} onKeyDown={event=>{handleEventKey(event,"7")}} 
                  onChange={(event)=>{setCardDetails({...cardDetails, familyAddress:event.target.value})}}
                  />
              </Form.Group>
            </Col>
           </Row>
          
    {familyCardFormType==="Edit"?<div className="mt-3 text-center">
            <Button variant="primary" type="submit" onClick={(event)=>{handleSubcriberEdit(event)}}>Update</Button>
        </div>:familyCardFormType==="New"?<div className="mt-3 text-center">
            <Button variant="primary" type="submit" onClick={(event)=>{handleNewFamilyCard(event)}}>Save</Button>
    </div>:null}
    
</Card.Body>
</Card>
      </div>

    {viewFamilyDetails?<div ref={componentRef} className="table-settings mb-4">
        <Tabs fill defaultActiveKey="Renewal" id="uncontrolled-tab-example"       
            activeKey={tabValue} onSelect={(k) => setTabValue(k)} className="mb-3">
        <Tab eventKey="Members" title={"Family Members - (" + displayMembers.length + ")"} >
            {tabValue==="Members"?<ListFamilyMembers displayMembers={displayMembers} toggleMemberForm={toggle} collapsed={collapsed} familyCardFormType={familyCardFormType}/>:null}
        </Tab>
        <Tab eventKey="Sacrements" title="Sacrements">
            {tabValue==="Sacrements"?<ListFamilySacrements displaySacrements={displaySacrements} toggleSacrementForm={toggleSacrements} familyCardFormType={familyCardFormType}/>:null}
        </Tab>
        </Tabs>
    </div>:null}
</div>
)
}
