import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import { faHome, faEye, faEllipsisH, faCheck, faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactToPrint from "react-to-print";
import { Button, Dropdown, Table, ButtonGroup, Breadcrumb } from '@themesberg/react-bootstrap';
import { Auth } from 'aws-amplify';
import { CSVLink } from "react-csv";
import {downloadAdminHeaders} from '../../ProData';
import AdminForm from './AdminForm';

const config = require('../../config.json');

export default function ListAdmins() {
  //const {applicationDetails, setApplicationDetails, status} = props;
  const { alertClose, alertDetails, setAlertDetails, loggedUser, validateLogin } = useContext(ProductContext);
  const [loaded, setLoaded] = useState(false);
  const [adminList, setAdminList] = useState([]);
  const [action, setAction] = useState('View');
  const componentRef = React.useRef(null);
  const csvLinkEl = React.createRef();
  const [newCollapsed, setNewCollapsed] = useState(true);
  const [selectedItem, setSelectedItem] = useState({});

  //const [selectedItem, setSelectedItem]=useState('');
//  const [alertDetails, setAlertDetails] = useState({style:"", open:false, message:""});
  //const [collapsed, setCollapsed] = useState(true);
  
useEffect(()=>{
  //console.log("props : " , props);
  if(loggedUser.isAuthenticated){
    alertClose();
    fetchSubscribersDetails();  
  }else{
    validateLogin();
  }
  },[])


const fetchSubscribersDetails=async()=>{
  try{
      setLoaded(false);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      const fetchOption="AdminStatus";
      const fetchKey="Active";
      const clientID=loggedUser.familyName;
      await axios.get(`${config.api.invokeUrl}/dashboard/adminform?fetchKey=${fetchKey}&fetchOption=${fetchOption}&clientID=${clientID}`,{
          headers: {
            Authorization: access_token,
            'x-api-key': config.api.key
          }}, 
      ).then((response)=>{
        //console.log("Response : ", response);
          setAdminList(response.data);
          setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: response.data.length + " - Admin records Found"
          });  
          //console.log(response);
          setLoaded(true);
      }).catch((error)=>{
          console.log(error);
          setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + error.message
          }); 
          setLoaded(true);
          //setDispMsg(error.message);
      })
  
  }catch(error){
      setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      }); 
      setLoaded(true);
  }
}

const handleDownload =async(event)=>{
  event.preventDefault();
  csvLinkEl.current.link.click();
}

const handleAdminAdd = (event, type, item)=>{
  event.preventDefault();
  try{
    setAction(type);
    newToggle();
    if(type==="View"){
      setSelectedItem(item);
    }
  }catch(error){
    console.log("Error : ", error);
  }
}

const newToggle = () => {
  //   console.log("clicked");
        setNewCollapsed(!newCollapsed);
  }


const reactToPrintContent = React.useCallback(() => {
  return componentRef.current;
}, [componentRef.current]);

const reactToPrintTrigger = React.useCallback(() => {
  return <Button variant="outline-primary"  size="sm">Print</Button>;
}, []);

  return (
    <>
    <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
    {alertDetails.message}
    </Alert>

    <Modal toggle={newToggle} isOpen={!newCollapsed} style={{top:'10%', maxWidth:'800px'}}>
            <ModalHeader toggle={newToggle}>
            Admin - {action}
            </ModalHeader>
            <ModalBody>    
            <div>
            <AdminForm formType={action} newToggle={newToggle} selectedItem={selectedItem}/> 
              </div>
            </ModalBody>
            <ModalFooter>
            {' '}
            <Button onClick={newToggle}>
                Close
            </Button>
            </ModalFooter>
          </Modal>

    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Admin</Breadcrumb.Item>
            <Breadcrumb.Item active>List Admins</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Manage Admins</h4>
       
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <Button variant="primary" type="submit" onClick={event=>handleAdminAdd(event, "New")}>New</Button>
          </ButtonGroup>
        </div>


        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="Registration"
        
        trigger={reactToPrintTrigger}
      />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadAdminHeaders}
              filename="download.csv"
              data={adminList}
              ref={csvLinkEl}
            />

          </ButtonGroup>
        </div>

      </div>

    {/*View*/}
   {/*} <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}}>
                <ModalHeader toggle={toggle}>
                </ModalHeader>
                <ModalBody>    
                    <RegistrationForm formType={"View"} profileDetails={profileDetails} setProfileDetails={setProfileDetails}/> 
                    <OptedCourseDetails formType={"View"} selectedCourse={profileDetails.selectedCourse} />
                    <AcademicInfo formType={"View"} form={profileDetails} setForm={setProfileDetails} />  
                    <FileAttachments idProof={idProof} certProof={certProof} />    
                </ModalBody>
                <ModalFooter>
        {' '}
        <Button onClick={toggle}>
            Close
        </Button>
        </ModalFooter>
  </Modal>*/}

    <Table ref={componentRef}  responsive hover className="user-table align-items-center">
         <thead>
            <tr>
            <th>#</th>
            <th>Admin ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone No</th>
            <th>Role</th>
            <th>Action</th>
            </tr>
        </thead>
        <tbody>
        {adminList.map((item, index)=>
        <tr key={index}>
        <th scope="row">{index+1}</th>
        <td>{item.adminID}</td>
        <td>{item.title}.{item.adminName}</td>
        <td>{item.adminEmailID}</td>
        <td>{item.adminPhoneNo}</td>
        <td>{item.chruchRole}</td>
        <td>
       <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
          <span className="icon icon-sm">
            <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item  onClick={event=>handleAdminAdd(event,"View", item)} >
            <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
        {/*<FontAwesomeIcon icon={faEye} className="me-2" onClick={(event)=>{handleApplicationView(event, item)}}/>
        <FontAwesomeIcon icon={faCheck} className="me-2" onClick={(event)=>{handleApplicationUpdate(event, item,"Approved")}}/>
        <FontAwesomeIcon icon={faCancel} className="me-2" onClick={(event)=>{handleApplicationUpdate(event, item,"Declined")}}/>
        
        <button onClick={(event)=>{handleProjectView(event, item)}}>View</button>
        <button onClick={(event)=>{handleProjectDelete(event, item)}}>Delete</button>
        <button onClick={(event)=>{handleProjectEdit(event, item)}}>Edit</button>*/}
        </td>
        </tr>)}
        </tbody>
        </Table>

</>
  )
}
