import React, { useContext, useEffect, useState} from "react";
import { Card, CardBody } from 'reactstrap';
//import logo from '../../assets/img/icons/mduchh.jpeg';
import { ProductContext } from '../../ProductProvider';
import {Helmet} from 'react-helmet-async';
import Preloader from "../../components/Preloader";

const config = require('../../config.json');

export default function WelcomeDash() {
  const [loaded, setLoaded] = useState(true);
  const { alertClose, loggedUser, validateLogin, churchMaster } = useContext(ProductContext);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
    }else{
      setLoaded(false);
      validateLogin();
      setLoaded(true);
    }
    return()=>{
      alertClose();
    }
  },[loggedUser])

  return (
    <div className='container text-center'>
    <Helmet>
      <title>Admin Office - {churchMaster.chruchFullName}</title>
      <meta name="description" content="Church Admin Office" />
      <meta name="keywords" content="Church Admin Office" /> 
    </Helmet>
    <Preloader show={loaded ? false : true} />
       {loaded &&  <Card>
            <CardBody>
                <h3 className='text-info' style={{fontFamily:'sans-serif'}}>WELCOME <br/>{churchMaster.chruchFullName}</h3>
                <img src={`${config.s3.imageURL}${churchMaster.chruchName}${"/mastar/images/"}${churchMaster.chruchName}`+".jpeg"} alt='logo' style={{borderRadius:20}} />
                <h1>Admin Portal</h1>
            </CardBody>
        </Card>}
    </div>
  )
}
