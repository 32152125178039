import React, { useEffect, useState, useContext, useRef } from "react";
import { Col, Row, Card, Form } from 'react-bootstrap';
import {Input, Alert} from 'reactstrap';
import ReactToPrint from "react-to-print";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faEdit, faRefresh, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup, Breadcrumb } from '@themesberg/react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../components/Preloader";
import { Auth } from "aws-amplify";
import {Helmet} from 'react-helmet-async';

//import FileAttachments from "../../components/attachments/FileAttachments";
//import ProfileImage from "../../components/manageimage/ProfileImage";
import { phoneCode, journalCategory } from "../../ProData";
import axios from "axios";
const config = require('../../config.json');
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;


export  default function SubscriberForm(props) {
  const { handleSearch, toggle} = props;
  const { loggedUser, alertClose, alertDetails, setAlertDetails, generateDate, validateLogin, subscriberFormType, setSubscriberFormType, subscriberDetails, setsubscriberDetails, churchMaster } = useContext(ProductContext);
  const [loaded, setLoaded] = useState(true);
  const [viewOnly, setViewOnly] = useState(true);
  const [subViewOnly, setSubViewOnly] = useState(true);
  const componentRef = React.useRef(null);
  const inputTitle = useRef(null);
  const inputAddressCity=useRef(null);
  const inputAddressPincode=useRef(null);
  const inputAddressState=useRef(null);
  const inputAreaCode=useRef(null);
  const inputCategoryID=useRef(null);
  const inputContactNo=useRef(null);
  const inputCopies=useRef(null);
  const inputCountry=useRef(null);
  const inputHouseName=useRef(null);
  const inputAddressStreet=useRef(null);
  const inputEmailID =useRef(null);
  const inputRegDate=useRef(null);
  const inputEndDate=useRef(null);
  const inputStartDate=useRef(null);
  const inputSubStatus=useRef(null);
  const inputSubscriberName=useRef(null);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      if(subscriberFormType==="New"){
        setViewOnly(false);
        resetValues();
        //generateID();
    }else if(subscriberFormType==="Edit"){
      setViewOnly(false);
    }else if(subscriberFormType==="View"){
      setViewOnly(true);
    }
  }else{
    resetValues();
    validateLogin();
  }
    return () => {
        setSubscriberFormType("New");
        resetValues();
      }
  },[]) 
  
  const handleEventKey=(event, item)=>{
    if(event.key === 'Enter'){
        if(item==="1"){
            inputSubscriberName.current.focus();
        }else if(item==="2"){
          inputRegDate.current.focus();
        }else if(item==="3"){
          inputSubStatus.current.focus();
        }else if(item==="4"){
          inputStartDate.current.focus();
        }else if(item==="5"){
          inputEndDate.current.focus();
        }else if(item==="6"){
          inputEmailID.current.focus();
        }else if(item==="7"){
          //console.log("event : ", event);
            inputAreaCode.current.focus();
        }else if(item==="8"){
          inputContactNo.current.focus();
        }else if(item==="9"){
          inputCategoryID.current.focus();
        }else if(item==="10"){
          inputCopies.current.focus();
        }else if(item==="11"){
          inputHouseName.current.focus();
        }else if(item==="12"){
          inputAddressStreet.current.focus();
        }else if(item==="13"){
          inputAddressCity.current.focus();
        }else if(item==="14"){
          inputAddressState.current.focus();
        }else if(item==="15"){
          inputAddressPincode.current.focus();
        }else if(item==="16"){
          inputCountry.current.focus();
        }
    }
}

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const handleSubcriberEdit=async(event)=>{
    event.preventDefault();
    try{
    alertClose();
    const validationResult=validateFields();
   //const validationResult="passed";
    if(validationResult==="passed"){

      setLoaded(false);
      const {title,subscriberName, subscriberID, areaCode,contactNo, emailID, houseName, addressStreet, addressCity, addressState,
      addressPincode, country, copies, categoryID,subStatus, regDate, startDate, endDate}=subscriberDetails;
      const tupdatedOn=await generateDate();
      const subscriberid=subscriberID;
      //const applicationStatus="Pending Approval";
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      //const tapplicantName=firstName+" "+middleName+ " "+lastName;
      const params={
        subscriberID: subscriberID, title:title,subscriberName:subscriberName, areaCode:areaCode, contactNo:contactNo, emailID:emailID, 
        houseName:houseName, addressStreet:addressStreet, addressCity:addressCity, addressState:addressState, addressPincode:addressPincode, 
        country:country, copies:copies, categoryID:categoryID, subStatus:subStatus, startDate:startDate, endDate:endDate,
        updatedBy:loggedUser.username, updatedOn:tupdatedOn, regDate:regDate};
      //console.log("Params : ", params);
      await axios.patch(`${config.api.invokeUrl}/subscribers/${subscriberid}`, params,{
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }})
        .then(async(response)=>{
          //console.log("response : ", response); 
       await handleSearch();
          setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: subscriberID + " - Successfully Updated."
        }); 
        window.scrollTo(0,0);
        toggle();
        setLoaded(true);
        }).catch((error)=>{
       //   console.log("error : ", error);
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: "Error Occured : " + error.message
          }); 
          window.scrollTo(0,0);
          setLoaded(true);
        })
    }
      
      }catch(error){
  //      console.log("error : ", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    window.scrollTo(0,0);
    setLoaded(true);
    }
  }

  const generateSubscriberID=()=>{
    const current_datetime = new Date();
    //const fName=profileDetails.firstName.trim().replace(/\s/g, "");
    //const tfName= fName.substring(0,2).toUpperCase();
    //const lName=profileDetails.lastName.trim().replace(/\s/g, "");
    //const tlName= lName.substring(0,2).toUpperCase();
    var tempUserId="";
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value);
    return accountNo;
}

  const handleSubcriberNew=async(event)=>{
    event.preventDefault();
    try{
    //  console.log("value : ", emailCodeEntered, emailCodeReceived);
    alertClose();
    const validationResult=validateFields();
    if(validationResult==="passed"){

      setLoaded(false);
      const {subscriberID, title,subscriberName, areaCode,contactNo, emailID, houseName, addressStreet, addressCity, addressState,
        addressPincode, country, copies, categoryID, regDate, startDate, endDate, subStatus}=subscriberDetails;
      //const clientID = "VJDEPTH";
      const todayDate=await generateDate();
      //const applicationStatus="Pending Approval";
      //const tcourseStatus="Pending Approval";
      //const tcoursePayment="Pending";   
      //const tapplicantName=firstName+ " "+middleName+ " "+lastName;    
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      const subscriberid=subscriberID;

      const params={
        subscriberID: subscriberid, title:title,subscriberName:subscriberName, areaCode:areaCode, contactNo:contactNo, emailID:emailID, 
        houseName:houseName, addressStreet:addressStreet, addressCity:addressCity, addressState:addressState, addressPincode:addressPincode, 
        country:country, copies:copies, categoryID:categoryID, subStatus:subStatus, updatedBy:loggedUser.username, updatedOn:todayDate,
        regDate:regDate, startDate:startDate, endDate:endDate};
     // console.log("Params : ", params);
      await axios.post(`${config.api.invokeUrl}/subscribers/${subscriberid}`, params,{
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }})
        .then(async(response)=>{
          console.log("response : ", response); 
          if(response.status===201){
          setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: "Subscriber ID - " + subscriberid + " - Successfully Registered."
        }); 
        window.scrollTo(0,0);
        resetValues();
      }else if(response.status===251){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Duplicate Subscriber ID - "+ subscriberid + " - Found. Correct or Refresh the Subscriber ID and Try Again."
      }); 
      window.scrollTo(0,0);      
      }
        setLoaded(true);
        }).catch((error)=>{
       //   console.log("error : ", error);
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: "Error Occured : " + error.message
          }); 
          window.scrollTo(0,0);
          setLoaded(true);
        })
        setLoaded(true);

    }
      
      }catch(error){
  //      console.log("error : ", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    window.scrollTo(0,0);
    setLoaded(true);
    }
  }

  const resetValues=()=>{
    const todayDate= generateDate();
    const subscriberid= generateSubscriberID();
    setsubscriberDetails({...subscriberDetails,subscriberID:subscriberid, title:'Mr',subscriberName:'', areaCode:'+91',contactNo:'', emailID:'', houseName:'', addressStreet:'', addressCity:'', addressState:'',
    addressPincode:'', country:'', copies:'', categoryID:'A',subStatus:'Active', regDate:todayDate, startDate:todayDate, endDate:todayDate});
  }

  const validateFields=()=>{
    const {subscriberID, title,subscriberName, areaCode,contactNo, emailID, houseName, addressStreet, addressCity, addressState,
        addressPincode, country, copies, categoryID}=subscriberDetails;      var returnValue="passed";
        if(subscriberID===""){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: "Subscriber ID cannot be empty!"
        }); 
        returnValue="failed";
        }else if(!emailID.match(validRegex)){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Enter correct Email Address!"
      }); 
      returnValue="failed";
      }else if(title===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Title cannot be Empty"
      }); 
      returnValue="failed";
    }else  if(subscriberName===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Name cannot be Empty"
    }); 
    returnValue="failed";
  }else  if(copies===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Copies cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(categoryID===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Category cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(areaCode===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Area Code cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(contactNo===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Mobile Number cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(emailID===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Email Address cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(addressCity===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "City cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(addressState===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "State cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(houseName===""||addressStreet===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Address cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(addressPincode===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Address Postal Code cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(country===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Country cannot be Empty"
  }); 
  returnValue="failed";
  }else {
    returnValue="passed";
  }
  window.scrollTo(0,0);
  return returnValue;
  }

  const handleSubscriberIDEdit=(e)=>{
    e.preventDefault();
    setSubViewOnly(false);
  }

  const handleSubscriberIDRefresh=(e)=>{
    e.preventDefault();
    const subscriberid= generateSubscriberID();
    setsubscriberDetails({...subscriberDetails,subscriberID:subscriberid});
  }

  const handleSubscriberIDClose=(e)=>{
    e.preventDefault();
    setSubViewOnly(true);
  }


  return (
    <div>
    <Helmet>
      <title>Register Member - Admin Office - {churchMaster.chruchFullName}</title>
      <meta name="description" content="Register Member - Church Admin Office" />
      <meta name="keywords" content="Register Member - Church Admin Office" /> 
    </Helmet>

        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>

        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>subscriberDetails</Breadcrumb.Item>
            <Breadcrumb.Item active>Registration Form</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="student"
        trigger={reactToPrintTrigger}
      />
          </ButtonGroup>
        </div>
        </div>
     
      <div ref={componentRef}>
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Row>
            <Col md={2} className="mb-3">
              <Form.Group id="title">
                <Form.Label>Title</Form.Label>
                <Form.Select value={subscriberDetails.title} 
                disabled={viewOnly} ref={inputTitle} onKeyDown={event=>{handleEventKey(event,"1")}} 
                onChange={(event)=>{setsubscriberDetails({...subscriberDetails, title:event.target.value})}}
               >
                  <option value="Fr">Fr</option>
                  <option value="Fr">Dr</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Sr">Sr</option>
                  <option value="Br">Br</option>
                  <option value="Rev">Rev</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Full Name</Form.Label>
                <Form.Control type="text"  autoFocus placeholder="Enter Full Name" value={subscriberDetails.subscriberName}
                  disabled={viewOnly} ref={inputSubscriberName} onKeyDown={event=>{handleEventKey(event,"2")}} 
                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, subscriberName:(event.target.value).toUpperCase()})}}  
               />
              </Form.Group>
            </Col>
            <Col md={3} className="mb-3">
              <Form.Group id="subscriberid">
                <Form.Label>Subscriber ID</Form.Label>
                <Form.Control required type="text" placeholder="Subscriber ID" value={subscriberDetails.subscriberID}
                  disabled={subViewOnly}
                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, subscriberID:(event.target.value).toUpperCase()})}}  
               />
              </Form.Group>

            </Col>
            <Col md={1} className="mb-3">
            <Form.Group id="subsid">
                <Form.Label> </Form.Label>
            {subViewOnly ? <><div><FontAwesomeIcon icon={faEdit} onClick={event=>{handleSubscriberIDEdit(event)}}/></div>
            <div><FontAwesomeIcon icon={faRefresh} onClick={event=>{handleSubscriberIDRefresh(event)}}/></div></>:
            <div><FontAwesomeIcon icon={faCheck} onClick={event=>{handleSubscriberIDClose(event)}}/></div>}

            </Form.Group>

            </Col>

        
          </Row> 
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="hname">
                <Form.Label>Date Registered</Form.Label>
                <Form.Control type="date" value={subscriberDetails.regDate}
                  disabled={viewOnly} ref={inputRegDate} onKeyDown={event=>{handleEventKey(event,"3")}} 
                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, regDate:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Status</Form.Label>
                <Form.Control type="text" placeholder="Status" value={subscriberDetails.subStatus}
                  disabled={viewOnly} ref={inputSubStatus} onKeyDown={event=>{handleEventKey(event,"4")}} 
                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, subStatus:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="hname">
                <Form.Label>Start Date</Form.Label>
                <Form.Control required type="date" value={subscriberDetails.startDate}
                  disabled={viewOnly} ref={inputStartDate} onKeyDown={event=>{handleEventKey(event,"5")}} 
                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, startDate:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>End Date</Form.Label>
                <Form.Control required type="date"  value={subscriberDetails.endDate}
                  disabled={viewOnly} ref={inputEndDate} onKeyDown={event=>{handleEventKey(event,"6")}} 
                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, endDate:event.target.value})}}
                  />
              </Form.Group>
            </Col>
          </Row>
                  
          <Row>
          <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="email" value={subscriberDetails.emailID}
                  disabled={viewOnly} ref={inputEmailID} onKeyDown={event=>{handleEventKey(event,"7")}} 
                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, emailID:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col>
            <Form.Label>Contact Number</Form.Label>
            <Row>
              <Col sm={4} className="mb-3">
              <Form.Group id="areanumber">
                
                <Input type="select" name="courseName"
                                  value={subscriberDetails.areaCode} 
                                  disabled={viewOnly} ref={inputAreaCode} onKeyDown={event=>{handleEventKey(event,"8")}} 
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, areaCode:event.target.value})}}
                                >
                                  {phoneCode.map(item => {
                                return (<option key={item.Code} value={item.Code}>{item.Code}</option>);
                              })}
                  </Input>
                 </Form.Group>
            </Col>
             <Col sm={8} className="mb-3">
              <Form.Group id="mnumber">
                <Form.Control required type="number" placeholder="Enter your contact number" 
                                disabled={viewOnly} ref={inputContactNo} onKeyDown={event=>{handleEventKey(event,"9")}} 
                                value={subscriberDetails.contactNo} onChange={(event)=>{setsubscriberDetails({...subscriberDetails, contactNo:event.target.value})}}
                />
              </Form.Group>
            </Col>
            </Row>
            </Col>
            
            
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="category">
                <Form.Label>Category</Form.Label>
                <Form.Select value={subscriberDetails.categoryID}
                        disabled={viewOnly} ref={inputCategoryID} onKeyDown={event=>{handleEventKey(event,"10")}} 
                        onChange={(event)=>{setsubscriberDetails({...subscriberDetails, categoryID:event.target.value})}}>
                  {journalCategory.map(item => {
                    return (
                    <option key={item.id} value={item.category}>{item.category}</option>
                    );
                  })}
            </Form.Select>              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="copies">
                <Form.Label>No. of Copies</Form.Label>
                <Form.Control required type="number" value={subscriberDetails.copies}
                                  disabled={viewOnly} ref={inputCopies} onKeyDown={event=>{handleEventKey(event,"11")}} 
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, copies:event.target.value})}}
                  />
              </Form.Group>
            </Col>
          </Row>
          
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="hname">
                <Form.Label>House Name</Form.Label>
                <Form.Control required type="text" value={subscriberDetails.houseName}
                                  disabled={viewOnly} ref={inputHouseName} onKeyDown={event=>{handleEventKey(event,"12")}} 
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, houseName:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Address</Form.Label>
                <Form.Control required type="text" placeholder="Enter Full Address" value={subscriberDetails.addressStreet}
                                  disabled={viewOnly} ref={inputAddressStreet} onKeyDown={event=>{handleEventKey(event,"13")}} 
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, addressStreet:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="hname">
                <Form.Label>City</Form.Label>
                <Form.Control required type="text" value={subscriberDetails.addressCity}
                                  disabled={viewOnly} ref={inputAddressCity} onKeyDown={event=>{handleEventKey(event,"14")}} 
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, addressCity:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>State</Form.Label>
                <Form.Control required type="text" placeholder="Enter State" value={subscriberDetails.addressState}
                                  disabled={viewOnly} ref={inputAddressState} onKeyDown={event=>{handleEventKey(event,"15")}} 
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, addressState:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <Form.Group id="zip">
                <Form.Label>Pincode</Form.Label>
                <Form.Control required type="tel" placeholder="Postal Pincode" value={subscriberDetails.addressPincode}
                                  disabled={viewOnly} ref={inputAddressPincode} onKeyDown={event=>{handleEventKey(event,"16")}} 
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, addressPincode:event.target.value})}}
                />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group id="country">
                <Form.Label>Country</Form.Label>
                <Form.Control required type="text" placeholder="Enter Country" value={subscriberDetails.country}
                                  disabled={viewOnly} ref={inputCountry} onKeyDown={event=>{handleEventKey(event,"17")}} 
                                  onChange={(event)=>{setsubscriberDetails({...subscriberDetails, country:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
            
          </Row>    
          {subscriberFormType==="Edit"?<div className="mt-3 text-center">
                  <Button variant="primary" type="submit" onClick={(event)=>{handleSubcriberEdit(event)}}>Update</Button>
                </div>:subscriberFormType==="New"?<div className="mt-3 text-center">
                  <Button variant="primary" type="submit" onClick={(event)=>{handleSubcriberNew(event)}}>Save</Button>
                </div>:null}
    
</Card.Body>
</Card>
</div>
</div>
  )
}
