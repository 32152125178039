import React, { useEffect, useState, useContext, useRef } from "react";
import { Col, Row, Card, Form } from 'react-bootstrap';
import { Input, Modal, ModalBody, ModalHeader, ModalFooter, Alert } from 'reactstrap';
import ReactToPrint from "react-to-print";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup, Breadcrumb } from '@themesberg/react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../components/Preloader";
import { Auth } from "aws-amplify";
import { phoneCode, genderData, familyRelationshipData, memberTitleData, educationData, professionData, memberStatusData, majorSubjectData, jobTypeData } from "../../ProData";
import axios from "axios";
import ProfileImage from "../profile/ProfileImage";

const config = require('../../config.json');
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;


export  default function FamilyMembersForm(props) {
  const { toggleMemberForm, displayMembers, setDisplayMembers, displaySacrements, setDisplaySacrements} = props;
  const { loggedUser, alertClose, alertDetails, setAlertDetails, generateDate, validateLogin, familyMemberFormType, setFamilyMemberFormType, cardDetails, familyMembers, setFamilyMembers, setNewImage, newImage, setCardDetails } = useContext(ProductContext);
  const [profileImage, setProfileImage] = useState('defaultAvatar.jpg');
  const [newProfileImage, setNewProfileImage]=useState('defaultAvatar.jpg')
  const [collapsed, setCollapsed] = useState(true);
  const [otherCollapsed, setOtherCollapsed] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [viewOnly, setViewOnly] = useState(true);
  const componentRef = React.useRef(null);
  const inputAddressCity=useRef(null);
  const inputAddressPincode=useRef(null);
  const inputAddressState=useRef(null);
  const inputAreaCode=useRef(null);
  const inputCategoryID=useRef(null);
  const inputContactNo=useRef(null);
  const inputCopies=useRef(null);
  const inputCountry=useRef(null);
  const inputHouseName=useRef(null);
  const inputAddressStreet=useRef(null);
  const inputEmailID =useRef(null);
  const inputRegDate=useRef(null);
  const inputEndDate=useRef(null);
  const inputStartDate=useRef(null);
  const inputSubStatus=useRef(null);
  const inputSubscriberName=useRef(null);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      //console.log("Family Member : ", familyMembers)
      if(familyMemberFormType==="New"){
        setViewOnly(false);
        resetValues();
        setLoaded(true);
        //generateID();
    }else if(familyMemberFormType==="Edit"){
      setLoaded(false);
      setViewOnly(false);
      const clientID=loggedUser.familyName;
      const familyid=familyMembers.memberID.substring(0,10);
      const tempPic=clientID+"/"+familyid+"/"+familyMembers.memberID+".jpeg";
      setProfileImage(tempPic);
      setLoaded(true);
    }else if(familyMemberFormType==="View"){
      setLoaded(false);
      setViewOnly(true);
      const clientID=loggedUser.familyName;
      const familyid=familyMembers.memberID.substring(0,10);
      const tempPic=clientID+"/"+familyid+"/"+familyMembers.memberID+".jpeg";
      setProfileImage(tempPic);
      setLoaded(true);
    }
    setLoaded(true);
  }else{
    resetValues();
    validateLogin();
  }
    return () => {
        if(familyMemberFormType==="New"){
        resetValues();
        }
        setFamilyMemberFormType("New");
      }
  },[]) 
  
  const handleEventKey=(event, item)=>{
    if(event.key === 'Enter'){
        if(item==="1"){
            inputSubscriberName.current.focus();
        }else if(item==="2"){
          inputRegDate.current.focus();
        }else if(item==="3"){
          inputSubStatus.current.focus();
        }else if(item==="4"){
          inputStartDate.current.focus();
        }else if(item==="5"){
          inputEndDate.current.focus();
        }else if(item==="6"){
          inputEmailID.current.focus();
        }else if(item==="7"){
          //console.log("event : ", event);
            inputAreaCode.current.focus();
        }else if(item==="8"){
          inputContactNo.current.focus();
        }else if(item==="9"){
          inputCategoryID.current.focus();
        }else if(item==="10"){
          inputCopies.current.focus();
        }else if(item==="11"){
          inputHouseName.current.focus();
        }else if(item==="12"){
          inputAddressStreet.current.focus();
        }else if(item==="13"){
          inputAddressCity.current.focus();
        }else if(item==="14"){
          inputAddressState.current.focus();
        }else if(item==="15"){
          inputAddressPincode.current.focus();
        }else if(item==="16"){
          inputCountry.current.focus();
        }
    }
}

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const handleSubcriberEdit=async(event)=>{
    event.preventDefault();
    try{
    alertClose();
    const validationResult=validateFields();
   //const validationResult="passed";
    if(validationResult==="passed"){

      setLoaded(false);
      const {memberID, title,memberName, areaCode,phoneNo, emailID, relationship, gender, education, majorSubject, profession, 
        otherJob, memberStatus, dateOfBirth, placeOfBirth, familyHead, registeredDate, deathDate, deathPlace, deathChurch, 
        newCardDate, newCardNo, newChurchName, Comments, burriedPlace }=familyMembers;
      const tupdatedOn=await generateDate();
      //const subscriberid=subscriberID;
      //const applicationStatus="Pending Approval";
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      const clientID=loggedUser.familyName;
      //const tapplicantName=firstName+" "+middleName+ " "+lastName;
      var totherJob=otherJob;
      if(otherJob===undefined){
        totherJob="NA";
      }
      var tmajorSubject=majorSubject;
      if(majorSubject===undefined){
        tmajorSubject="NA";
      }

      const params={
        clientID:clientID, memberID: memberID, title:title,memberName:memberName, areaCode:areaCode, phoneNo:phoneNo, emailID:emailID, 
        relationship:relationship, gender:gender, education:education, majorSubject:tmajorSubject, profession:profession, otherJob:totherJob, 
        memberStatus:memberStatus, dateOfBirth:dateOfBirth, placeOfBirth:placeOfBirth, familyHead:familyHead, updatedBy:loggedUser.username, 
        updatedOn:tupdatedOn, registeredDate:registeredDate, deathDate:deathDate, deathPlace:deathPlace, deathChurch:deathChurch, 
        newCardDate:newCardDate, newCardNo:newCardNo, newChurchName:newChurchName, Comments:Comments, burriedPlace:burriedPlace};
        console.log("Params :", params);

      await axios.patch(`${config.api.invokeUrl}/dashboard/family_members`, params,{
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }})
        .then(async(response)=>{
          if(newImage){
            var uploadStatus="Failed";
            uploadStatus= await postOnS3(memberID, cardDetails.familyID);  
          } 
          //testing
          const tmembers=displayMembers;
          const memberToUpdate = tmembers.find(obj => obj.memberID === memberID);
          const updatedMembers = tmembers.filter(obj => obj.memberID !== memberID);
          memberToUpdate.memberID = memberID;
          memberToUpdate.memberName = memberName;
          memberToUpdate.areaCode = areaCode;
          memberToUpdate.phoneNo = phoneNo;
          memberToUpdate.emailID = emailID;
          memberToUpdate.relationship = relationship;
          memberToUpdate.gender = gender;
          memberToUpdate.education = education;
          memberToUpdate.majorSubject=majorSubject;
          memberToUpdate.profession = profession;
          memberToUpdate.otherJob=otherJob;
          memberToUpdate.memberStatus = memberStatus;
          memberToUpdate.dateOfBirth = dateOfBirth;
          memberToUpdate.placeOfBirth = placeOfBirth;
          memberToUpdate.familyHead = familyHead;
          if(memberStatus==="Dead"){
          memberToUpdate.deathDate = deathDate;
          memberToUpdate.deathPlace = deathPlace;
          memberToUpdate.deathChurch = deathChurch;
          memberToUpdate.burriedPlace = burriedPlace;
          }
          if(memberStatus==="Dead"){
            memberToUpdate.newCardDate = newCardDate;
            memberToUpdate.newCardNo = newCardNo;
            memberToUpdate.newChurchName = newChurchName;
            memberToUpdate.Comments = Comments;
            }
          updatedMembers.push(memberToUpdate);
          setDisplayMembers(updatedMembers);
          const tsacrements=displaySacrements;
          const sacrementsToUpdate = tsacrements.find(obj => obj.memberID === memberID);
          const updatedSacrements = tsacrements.filter(obj => obj.memberID !== memberID);
          sacrementsToUpdate.memberName = memberName;
          sacrementsToUpdate.memberStatus = memberStatus;
          updatedSacrements.push(sacrementsToUpdate);
          setDisplaySacrements(updatedSacrements); 
          if(familyHead==="Yes"){
            setCardDetails({...cardDetails, familyHead:memberName, familyMembers:tmembers.length})
          }else{
            setCardDetails({...cardDetails, familyMembers:tmembers.length})
          }
          //testing
          setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: memberID + " - Successfully Updated."
        }); 
        window.scrollTo(0,0);
        toggleMemberForm();
        setLoaded(true);
        }).catch((error)=>{
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: "Error Occured : " + error.message
          }); 
          window.scrollTo(0,0);
          setLoaded(true);
        })
    }
      }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    window.scrollTo(0,0);
    setLoaded(true);
    }
  }

  /*const generateSubscriberID=()=>{
    const current_datetime = new Date();
    //const fName=profileDetails.firstName.trim().replace(/\s/g, "");
    //const tfName= fName.substring(0,2).toUpperCase();
    //const lName=profileDetails.lastName.trim().replace(/\s/g, "");
    //const tlName= lName.substring(0,2).toUpperCase();
    var tempUserId="";
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value);
    return accountNo;
}*/

const handleFamilyHeadSelection=(event)=>{
  //console.log("event :", event.target.value);
  const selection=event.target.value;
  //console.log("Selection : ", selection);
  if(selection==="Family Head"){
    let filteredUsers = displayMembers.filter(member => member.relationship === 'Family Head');
      //console.log("filtered user :", filteredUsers);
    if(filteredUsers.length>0){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: filteredUsers[0].memberName + " is already marked as Family Head. Correct this before adding new Family Head"
    }); 
    window.scrollTo(0,0);      
    }else{  
    setFamilyMembers({...familyMembers,
      relationship:selection});
  }
}else{  
  setFamilyMembers({...familyMembers,
    relationship:selection});
}
}

  const handleAddMember=async(event)=>{
    event.preventDefault();
    try{
    alertClose();
    const validationResult=validateFields();
    if(validationResult==="passed"){
      setLoaded(false);
      const {memberID, title,memberName, areaCode,phoneNo, emailID, relationship, gender, education, majorSubject, profession, otherJob,
        memberStatus, dateOfBirth, placeOfBirth, deathDate, deathPlace, deathChurch, newCardDate,
        newCardNo, newChurchName, Comments, burriedPlace }=familyMembers;
        //phoneNumber adjustment
        var tempPhoneNo=phoneNo;
        if(tempPhoneNo===""){
          tempPhoneNo="NA";
        }
      const todayDate=await generateDate();
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      const memberid=memberID;
      const clientID=loggedUser.familyName;

      const params={
        memberID: memberid, title:title,memberName:memberName, areaCode:areaCode, phoneNo:phoneNo, emailID:emailID, 
        relationship:relationship, gender:gender, education:education, majorSubject:majorSubject, profession:profession, otherJob:otherJob, 
        memberStatus:memberStatus, dateOfBirth:dateOfBirth, placeOfBirth:placeOfBirth, updatedBy:loggedUser.username, updatedOn:todayDate,
        registeredDate:todayDate, deathDate:deathDate, deathPlace:deathPlace, deathChurch:deathChurch, newCardDate:newCardDate,
        newCardNo:newCardNo, newChurchName:newChurchName, Comments:Comments, burriedPlace:burriedPlace };

      const sacParams={
        memberID:memberID, title:title, memberName:memberName, isBaptised:'No', baptismDate:'No',isEucharist:'No', eucharistDate:'No', isConfirmation:'No', 
        confirmationDate:'No',isMarried:'No',marriageDate:'No',
        isDead:'No', deathDate:'NA',confirmationPlace:'NA',confirmationChurch:'NA',marriagePlace:'NA',marriageChurch:'NA',deathPlace:'NA',deathChurch:'NA',
        burriedPlace:'NA',baptismChruch:'NA',baptismPlace:'NA',eucharistChruch:'NA', eucharistPlace:'NA'};

      const inputParams={
        clientID: clientID, memberID: memberid, title:title,memberName:memberName, areaCode:areaCode, phoneNo:tempPhoneNo, emailID:emailID, 
        relationship:relationship, gender:gender, education:education, majorSubject:majorSubject, profession:profession, otherJob:otherJob, 
        memberStatus:memberStatus, dateOfBirth:dateOfBirth, placeOfBirth:placeOfBirth, updatedBy:loggedUser.username, updatedOn:todayDate,
        registeredDate:todayDate, isBaptised:'No', baptismDate:'No',isEucharist:'No', eucharistDate:'No', isConfirmation:'No', 
        confirmationDate:'No',isMarried:'No',marriageDate:'No', confirmationPlace:'NA', confirmationChurch:'NA',marriagePlace:'NA',marriageChurch:'NA',
        burriedPlace:burriedPlace,baptismChruch:'NA',baptismPlace:'NA',eucharistChruch:'NA', eucharistPlace:'NA', deathDate:deathDate, deathPlace:deathPlace, 
        deathChurch:deathChurch, newCardDate:newCardDate, newCardNo:newCardNo, newChurchName:newChurchName, Comments:Comments};

        //console.log("input : ", inputParams);
      await axios.post(`${config.api.invokeUrl}/dashboard/family_members`, inputParams,{
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }})
        .then(async(response)=>{
          if(response.status===201){
            if(newImage){
              var uploadStatus="Failed";
              uploadStatus= await postOnS3(memberid, cardDetails.familyID);  
            } 
            // testing
           /* const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;  
            var tsearchValue=memberid;
            const searchTopic="FamilyID";
            const clientID=loggedUser.familyName;
            await axios.get(`${config.api.invokeUrl}/dashboard/family_card?fetchKey=${tsearchValue}&fetchOption=${searchTopic}&clientID=${clientID}`, {
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
            console.log("response : ", response);
            if(response.data.length>0)
            {
                //setCardDetails(response.data[0]);
                if(searchTopic==="FamilyID"){
                  setDisplayMembers(response.data[1]);
                  setDisplaySacrements(response.data[2]);
                }
            }
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: response.data[0].length + " - Family card records found!"
          });  
            setLoaded(true);
        }).catch((error)=>{
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        });        
        setLoaded(true);
      })*/

      //testing
            const tmembers=displayMembers;
            tmembers.push(params);
            setDisplayMembers(tmembers);
            const tsacrements=displaySacrements;
            tsacrements.push(sacParams);
            setDisplaySacrements(tsacrements); 
            setCardDetails({...cardDetails, familyMembers:tmembers.length})

            toggleMemberForm();     
          setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: "Member ID - " + memberID + " - Successfully Registered."
        }); 
        window.scrollTo(0,0);
        resetValues();
      }else if(response.status===251){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Duplicate Member ID - "+ memberID + " - Found. Correct or Refresh the Member ID and Try Again."
      }); 
      window.scrollTo(0,0);      
      }
        setLoaded(true);
        }).catch((error)=>{
       //   console.log("error : ", error);
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: "Error Occured : " + error.message
          }); 
          window.scrollTo(0,0);
          setLoaded(true);
        })
        setLoaded(true);
      }
      }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    window.scrollTo(0,0);
    setLoaded(true);
    }
    //window.focus();
    window.scrollTo(0,0);
  }

  const toggle = () => {
    setCollapsed(!collapsed);
  }

  const toggleOther = () => {
    setOtherCollapsed(!otherCollapsed);
  }
  const handleEducationChange=(event)=>{
    const tempValue=event.target.value;
    if(tempValue===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: " Subject cannot be blank"
    }); 
    }else if(tempValue==="Diploma"||tempValue==="I.T.I"||tempValue==="Under Graduation"||tempValue==="Post Graduation"||tempValue==="Doctrate"){
      setFamilyMembers({...familyMembers, education:event.target.value});
      toggle();
    }else{
      setFamilyMembers({...familyMembers, education:event.target.value});
    }
  }

  const handleProfesssionalChange=(event)=>{
    const tempValue=event.target.value;
    if(tempValue==="Others"){

    }else{
      setFamilyMembers({...familyMembers, profession:event.target.value});
    }
  }

  const resetValues=()=>{
    const todayDate= generateDate();
    //const memberID= generateSubscriberID();
    const memberID = cardDetails.familyID +"-"+ (displayMembers.length + 1);
    setFamilyMembers({...familyMembers,memberID:memberID, title:'Mr',memberName:'', areaCode:'+91',phoneNo:'', emailID:'', relationship:'Family Head', 
    gender:'Male', education:'No Education', majorSubject:'NA', profession:'Student', otherJob:'NA',
    memberStatus:'Active', dateOfBirth:'', placeOfBirth:'', registeredDate:'', deathDate:'', deathPlace:'', deathChurch:'', newCardDate:'',
    newCardNo:'', newChurchName:'', Comments:'', burriedPlace:''});
  }

  const postOnS3 = async(fileName, familyid)=>{
    try{
      const newFileName=fileName+".jpeg";      
      var file = newImage;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
     // console.log("New file : ", newFile);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;   
      const clientID=loggedUser.familyName;
      const bucketName=config.s3.bucketName+"/"+clientID+"/"+familyid;
      await axios.get(`${config.api.invokeUrl}/dashboard/profile_avatar/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}`,{
           headers:{
               Authorization: access_token,
               'x-api-key': config.api.key
           }
       }).then(async(response)=>{
          // console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
           //  console.log("Res : ", res);
             if(res.status===200){
              setNewProfileImage(newFileName);
             }
        }).catch((error)=>{
            //console.log("error1:", error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            return "Failed";
         // setDispMsg(error.message);
         // hideLoader();
       })
    
    }catch(error){
    //  console.log("error1:", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    return "Failed";
    }
  return "Success";
  }

  const validateFields=()=>{
    const {memberID, title,memberName, areaCode,phoneNo, emailID, relationship, gender, education, profession,
        dateOfBirth, placeOfBirth, otherJob, majorSubject}=familyMembers;
        var returnValue="passed";
        let filteredUsers = displayMembers.filter(member => member.relationship === 'Family Head');
        //console.log("filtered user : ", filteredUsers, relationship);
        if(filteredUsers.length>=1 && relationship==="Family Head" && familyMemberFormType==="New"){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: "One Family Head is allowed for a family card. Check the relationship field and update it correctly!"
        }); 
        returnValue="failed";
        }else if(memberID===""){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: "Member ID cannot be empty!"
        }); 
        returnValue="failed";
        }/*else if(emailID!=="" && !emailID.match(validRegex)){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Enter correct Email Address!"
      }); 
      returnValue="failed";
      }else if(title===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Title cannot be Empty"
      }); 
      returnValue="failed";
    }*/else  if(memberName===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Name cannot be Empty"
    }); 
    returnValue="failed";
  }else  if(relationship===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Relationship cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(gender===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Gender cannot be Empty"
  }); 
  returnValue="failed";
  }else if(profession==="Others" && otherJob===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Job Details cannot be empty if the profession is 'Other'"
  }); 
  returnValue="failed";

  }else if((education==="Diploma"||education==="I.T.I"||education==="Under Graduation"||education==="Post Graduation"||education==="Doctrate") && majorSubject===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Major Subject cannot be blank if the education is 'Diploma' or 'I.T.I' or 'Under Graduation' or 'Post Graduation' or 'Doctrate'"
  }); 
  returnValue="failed";
  }
  /*else  if(areaCode===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Area Code cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(phoneNo===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Mobile Number cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(education===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Education cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(profession===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Profession cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(dateOfBirth===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Date Of Birth cannot be Empty"
  }); 
  returnValue="failed";
  }else  if(placeOfBirth===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Place Of Birth cannot be Empty"
  }); 
  returnValue="failed";
  }*/else {
    returnValue="passed";
  }
  return returnValue;
  }

  const handleSaveModal=(event)=>{
    event.preventDefault();
    setFamilyMembers({...familyMembers, majorSubject:event.target.value});
    toggle();
  }

  /*const handleSubscriberIDEdit=(e)=>{
    e.preventDefault();
    setSubViewOnly(false);
  }

  const handleSubscriberIDRefresh=(e)=>{
    e.preventDefault();
    const subscriberid= generateSubscriberID();
    setCardDetails({...cardDetails,subscriberID:subscriberid});
  }

  const handleSubscriberIDClose=(e)=>{
    e.preventDefault();
    setSubViewOnly(true);
  }*/

  return (
    <div>
        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>

        {/*Modal for EduInput*/}
       {/*} <div >
          <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%', maxWidth:'800px'}} >
          <div >
            <ModalHeader  toggle={toggle}>
            Additional Input Form
            </ModalHeader>
            <ModalBody >    
            <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <Row>
              <Col md={12} className="mb-3">
                    <Form.Group id="subscriberid">
                      <Form.Label>Subject</Form.Label>
                      <Form.Control required type="text" placeholder="Enter Subject" value={familyMembers.majorSubject}
                          disabled={viewOnly}
                          onChange={(event)=>{setFamilyMembers({...familyMembers, majorSubject:event.target.value})}} />
                    </Form.Group>
                  </Col>
              </Row>
            </Card.Body>
            </Card>
            </ModalBody>
            <ModalFooter>
            <Button onClick={event=>handleSaveModal(event)}>
                Save
            </Button>
            {' '}
            <Button onClick={toggle}>
                Close
            </Button>
            </ModalFooter>
          </div>       
        </Modal>
        </div>*/}

        {/*Modal for OtherInput*/}
       {/*} <div >
          <Modal toggle={toggleOther} isOpen={!otherCollapsed} style={{top:'10%', maxWidth:'800px'}} >
          <div >
            <ModalHeader  toggle={toggleOther}>
            Additional Input Form
            </ModalHeader>
            <ModalBody >    
            <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <Row>
              <Col md={12} className="mb-3">
                    <Form.Group id="subscriberid">
                      <Form.Label>Enter your Job details</Form.Label>
                      <Form.Control required type="text" placeholder="Enter Subject" value={familyMembers.otherJob}
                          disabled={viewOnly}
                          onChange={(event)=>{setFamilyMembers({...familyMembers, otherJob:event.target.value})}} />
                    </Form.Group>
                  </Col>
              </Row>
            </Card.Body>
            </Card>
            </ModalBody>
            <ModalFooter>
            <Button onClick={event=>handleSaveOtherModal(event)}>
                Save
            </Button>
            {' '}
            <Button onClick={toggleOther}>
                Close
            </Button>
            </ModalFooter>
          </div>       
        </Modal>
  </div>*/}


        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Registration</Breadcrumb.Item>
            <Breadcrumb.Item active>Members</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="student"
        trigger={reactToPrintTrigger}
      />
          </ButtonGroup>
        </div>
        </div>
      <div ref={componentRef}>
      <Card border="light" className="bg-white shadow-sm mb-4">
      {profileImage && loaded && <ProfileImage profileImage={profileImage} viewOnly={viewOnly} setNewImage={setNewImage}/>}
      <Card.Body>
        <Row>
        <Col md={2} className="mb-3">
              <Form.Group id="subscriberid">
                <Form.Label>Title</Form.Label>
                <Form.Select autoFocus placeholder="Title" value={familyMembers.title}
                  disabled={viewOnly} 
                  onChange={(event)=>{setFamilyMembers({...familyMembers, title:event.target.value})}}  
               >
                {memberTitleData.map(item => {
                    return (
                    <option key={item.name} value={item.name}>{item.name}</option>
                    );
                })}
                </Form.Select>
              </Form.Group>
            </Col>
        <Col md={6} className="mb-3">
              <Form.Group id="subscriberid">
                <Form.Label>Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter Name" value={familyMembers.memberName}
                  disabled={viewOnly}
                  onChange={(event)=>{setFamilyMembers({...familyMembers, memberName:(event.target.value).toUpperCase()})}}  
               />
              </Form.Group>
            </Col>
            {/*<Col md={1} className="mb-3">
            <Form.Group id="subsid">
                <Form.Label>Family Head</Form.Label>
                <Input type="checkbox" defaultChecked={false} checked={familyMembers.familyHead==="Yes"?true:false} 
                 disabled={viewOnly} onChange={(event)=>{handleFamilyHeadSelection(event)}}  />
                             </Form.Group>
            </Col>*/}

            {/*subViewOnly ? <><div><FontAwesomeIcon icon={faEdit} onClick={event=>{handleSubscriberIDEdit(event)}}/></div>
            <div><FontAwesomeIcon icon={faRefresh} onClick={event=>{handleSubscriberIDRefresh(event)}}/></div></>:
  <div><FontAwesomeIcon icon={faCheck} onClick={event=>{handleSubscriberIDClose(event)}}/></div>*/}
            <Col md={4} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Relationship</Form.Label>
                <Form.Select autoFocus placeholder="Relationship" value={familyMembers.relationship}
                  disabled={viewOnly} ref={inputSubscriberName} onKeyDown={event=>{handleEventKey(event,"2")}} 
                  onChange={(event)=>{handleFamilyHeadSelection(event)}}  
               >
                {familyRelationshipData.map(item => {
                    return (
                    <option key={item.name} value={item.name}>{item.name}</option>
                    );
                })}
                </Form.Select>
              </Form.Group>
            </Col>
        </Row> 
        <Row>
        <Col>
          <Form.Label>Phone Number</Form.Label>
          <Row>
            <Col sm={4} className="mb-3">
            <Form.Group id="areanumber">
              <Input type="select" name="courseName"
                      value={familyMembers.areaCode} 
                      disabled={viewOnly} ref={inputAreaCode}  
                      onChange={(event)=>{setFamilyMembers({...familyMembers, areaCode:event.target.value})}}>
                      {phoneCode.map(item => {
                  return (<option key={item.Code} value={item.Code}>{item.Code}</option>);
                  })}
                </Input>
                </Form.Group>
          </Col>
            <Col sm={8} className="mb-3">
            <Form.Group id="mnumber">
              <Form.Control required type="number" placeholder="Enter your contact number" 
                  disabled={viewOnly} ref={inputContactNo} onKeyDown={event=>{handleEventKey(event,"3")}} 
                  value={familyMembers.phoneNo} onChange={(event)=>{setFamilyMembers({...familyMembers, phoneNo:event.target.value})}}
              />
            </Form.Group>
          </Col>
          </Row>
          </Col>
          <Col sm={6} className="mb-3">
            <Form.Group className="mb-2">
              <Form.Label>Email</Form.Label>
              <Form.Control type="text" placeholder="Enter Email Address" value={familyMembers.emailID}
                disabled={viewOnly} ref={inputSubStatus} onKeyDown={event=>{handleEventKey(event,"4")}} 
                onChange={(event)=>{setFamilyMembers({...familyMembers, emailID:event.target.value})}}
                />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6} className="mb-3">
            <Form.Group id="gender">
              <Form.Label>Gender</Form.Label>
              <Form.Select required value={familyMembers.gender}
                disabled={viewOnly} ref={inputStartDate} onKeyDown={event=>{handleEventKey(event,"5")}} 
                onChange={(event)=>{setFamilyMembers({...familyMembers, gender:event.target.value})}}
                >
                  {genderData.map(item => {
                  return (
                  <option key={item.name} value={item.name}>{item.name}</option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="category">
              <Form.Label>Status</Form.Label>
              <Form.Select value={familyMembers.memberStatus}
                      disabled={viewOnly} ref={inputCategoryID} onKeyDown={event=>{handleEventKey(event,"10")}} 
                      onChange={(event)=>{setFamilyMembers({...familyMembers, memberStatus:event.target.value})}}>
              {memberStatusData.map(item => {
                  return (
                  <option key={item.name} value={item.name}>{item.name}</option>
                  );})}
          </Form.Select>              
          </Form.Group>
          </Col>
          </Row>
          <Row>
          <Col sm={6} className="mb-3">
            <Form.Group className="mb-2">
              <Form.Label>Education</Form.Label>
              <Form.Select required type="date"  value={familyMembers.education}
                disabled={viewOnly} ref={inputEndDate} onKeyDown={event=>{handleEventKey(event,"6")}} 
                //onChange={(event)=>{handleEducationChange(event)}}
                onChange={(event)=>{setFamilyMembers({...familyMembers, education:event.target.value})}}
                >
              {educationData.map(item => {
                  return (
                  <option key={item.name} value={item.name}>{item.name}</option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          {familyMembers.education==="Diploma"||familyMembers.education==="I.T.I"||familyMembers.education==="Under Graduation"||familyMembers.education==="Post Graduation"||familyMembers.education==="Doctrate" ?
         <Col sm={6} className="mb-3">
            <Form.Group className="mb-2">
              <Form.Label>Subject</Form.Label>
              <Form.Control required type="text" placeholder="Enter the Main Subject" value={familyMembers.majorSubject}
                  disabled={viewOnly}
                  onChange={(event)=>{setFamilyMembers({...familyMembers, majorSubject:event.target.value})}}
                  />
            </Form.Group>
          </Col>:null}
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="Profession">
              <Form.Label>Profession</Form.Label>
              <Form.Select value={familyMembers.profession}
                disabled={viewOnly} ref={inputEmailID} onKeyDown={event=>{handleEventKey(event,"7")}} 
                onChange={(event)=>{setFamilyMembers({...familyMembers, profession:event.target.value})}}
                >
                  {professionData.map(item => {
                  return (
                  <option key={item.name} value={item.name}>{item.name}</option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>  
          {familyMembers.profession==="Others"?<Col md={6} className="mb-3">
            <Form.Group id="emal">
              <Form.Label>Job Type</Form.Label>
              <Form.Control required type="text" placeholder="Enter the Job Details" value={familyMembers.otherJob}
                  disabled={viewOnly}
                  onChange={(event)=>{setFamilyMembers({...familyMembers, otherJob:event.target.value})}}
                  />
            </Form.Group>
              </Col> :null}
          
        </Row>
        <Row>
      <Col md={6} className="mb-3">
          <Form.Group id="emal">
            <Form.Label>Date Of Birth</Form.Label>
            <Form.Control type="date" value={familyMembers.dateOfBirth}
              disabled={viewOnly} ref={inputEmailID} onKeyDown={event=>{handleEventKey(event,"7")}} 
              onChange={(event)=>{setFamilyMembers({...familyMembers, dateOfBirth:event.target.value})}}
              />
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group id="category">
            <Form.Label>Place Of Birth</Form.Label>
            <Form.Control type="text" value={familyMembers.placeOfBirth}
                    disabled={viewOnly} ref={inputCategoryID} onKeyDown={event=>{handleEventKey(event,"10")}} 
                    onChange={(event)=>{setFamilyMembers({...familyMembers, placeOfBirth:event.target.value})}}/>
        </Form.Group>
        </Col>          
        </Row>
        {familyMembers.memberStatus==="Dead"?<Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="gender">
                <Form.Label>Date of Death</Form.Label>
                <Form.Control type="date" required value={familyMembers.deathDate}
                  disabled={viewOnly} ref={inputStartDate} onKeyDown={event=>{handleEventKey(event,"5")}} 
                  onChange={(event)=>{setFamilyMembers({...familyMembers, deathDate:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Place of Death</Form.Label>
                <Form.Control required type="text"  value={familyMembers.deathPlace}
                  disabled={viewOnly} ref={inputEndDate} onKeyDown={event=>{handleEventKey(event,"6")}} 
                  onChange={(event)=>{setFamilyMembers({...familyMembers, deathPlace:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Mass Offered Church Name & Place</Form.Label>
                <Form.Control required type="text"  value={familyMembers.deathChurch}
                  disabled={viewOnly} ref={inputEndDate} onKeyDown={event=>{handleEventKey(event,"6")}} 
                  onChange={(event)=>{setFamilyMembers({...familyMembers, deathChurch:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Burial Place</Form.Label>
                <Form.Control required type="text"  value={familyMembers.burriedPlace}
                  disabled={viewOnly} ref={inputEndDate} onKeyDown={event=>{handleEventKey(event,"6")}} 
                  onChange={(event)=>{setFamilyMembers({...familyMembers, burriedPlace:event.target.value})}}
                  />
              </Form.Group>
            </Col>

        </Row>:null}
        {familyMembers.memberStatus==="Transferred"?<Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="gender">
                <Form.Label>New Card Date</Form.Label>
                <Form.Control type="date" required value={familyMembers.newCardDate}
                  disabled={viewOnly} ref={inputStartDate} onKeyDown={event=>{handleEventKey(event,"5")}} 
                  onChange={(event)=>{setFamilyMembers({...familyMembers, newCardDate:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>New Card No</Form.Label>
                <Form.Control required type="text"  value={familyMembers.newCardNo}
                  disabled={viewOnly} ref={inputEndDate} onKeyDown={event=>{handleEventKey(event,"6")}} 
                  onChange={(event)=>{setFamilyMembers({...familyMembers, newCardNo:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>New Church Name & Place</Form.Label>
                <Form.Control required type="text"  value={familyMembers.newChurchName}
                  disabled={viewOnly} ref={inputEndDate} onKeyDown={event=>{handleEventKey(event,"6")}} 
                  onChange={(event)=>{setFamilyMembers({...familyMembers, newChurchName:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Comments</Form.Label>
                <Form.Control required type="text"  value={familyMembers.Comments}
                  disabled={viewOnly} ref={inputEndDate} onKeyDown={event=>{handleEventKey(event,"6")}} 
                  onChange={(event)=>{setFamilyMembers({...familyMembers, Comments:event.target.value})}}
                  />
              </Form.Group>
            </Col>


        </Row>:null}
        {familyMemberFormType==="Edit"?<div className="mt-3 text-center">
          <Button variant="primary" type="submit" onClick={(event)=>{handleSubcriberEdit(event)}}>Update</Button>
        </div>:familyMemberFormType==="New"?<div className="mt-3 text-center">
          <Button variant="primary" type="submit" onClick={(event)=>{handleAddMember(event)}}>Save</Button>
        </div>:null}
</Card.Body>
</Card>   
    </div>
    </div>
  )
}
