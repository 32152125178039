import React, {useState, useContext} from 'react';
import PaymentForm from './PaymentForm';
import { Auth } from "aws-amplify";
import axios from "axios";
import { Alert } from 'reactstrap';
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch , faHome, faCalendarCheck} from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, InputGroup, Breadcrumb, ButtonGroup } from '@themesberg/react-bootstrap';
import {Helmet} from 'react-helmet-async';
import PaymentMonthDisplay from './PaymentMonthDisplay';
import { Card } from "reactstrap";


const config = require('../../config.json');

function AddPayments() {
    const { loggedUser, alertClose, alertDetails, setAlertDetails, validateLogin, subscriberFormType, setSubscriberFormType, setCardDetails, churchMaster} = useContext(ProductContext);
    const [searchTopic, setSearchTopic] = useState('FamilyID');
    const [searchValue, setSearchValue] = useState('');
    const [loaded, setLoaded] = useState(true);
    const [searchResults, setSearchResults] = useState([]);
    const [formType, setFormType]= useState("None");
    const [viewCal, setViewCal] = useState(false);

    const handleSearch=async()=>{
        //event.preventDefault();
        setSearchResults([]);
        try{
          if(searchValue===""){
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: "Search Value is empty Enter the search input and then hit Search button!"
          });  
          }else{
            setLoaded(false);
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken; 
            const clientID=loggedUser.familyName; 
            var tsearchValue=searchValue
            if(searchTopic==="SubscriberName"){
              tsearchValue=searchValue.toUpperCase();
              setSearchValue(tsearchValue);
            }
            await axios.get(`${config.api.invokeUrl}/dashboard/family_card?fetchKey=${tsearchValue}&fetchOption=${searchTopic}&clientID=${clientID}`, {
              headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
            //console.log("Response : ", response);
            if(response.data[0].length>0)
            {
                setCardDetails(response.data[0][0]);
                setFormType("New");
                setSearchResults(response.data);
                //setSubscriberFormType("Add");
               // setCardDetails(response.data[0][0]);
            }else{        
                setFormType("None");        
            }
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: response.data[0].length + " - Family's records found!"
          });  
            setLoaded(true);
    
        }).catch((error)=>{
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        });        
        setLoaded(true);
      })
    }
        }catch(error){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        });        
        setLoaded(true);
        }
      }

    const handleToggle=()=>{
      setViewCal(!viewCal);
    }
  
    return (
    <div>
    <Helmet>
      <title>Contribution - Admin Office - {churchMaster.chruchFullName}</title>
      <meta name="description" content="Contribution - Church Admin Office" />
      <meta name="keywords" content="Contribution - Church Admin Office" /> 
    </Helmet>
    <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>

        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Contribution</Breadcrumb.Item>
            <Breadcrumb.Item active>New</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Add New Contribution</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          </ButtonGroup>
        </div>
      </div>

        <div className="table-settings mb-4">
        <Row className="justify-content-start align-items-center">
        <Col md={4} className="mb-3">
              <Form.Group id="searchstud">               
                <Form.Select defaultValue="FamilyID" value={searchTopic} onChange={(event)=>{setSearchTopic(event.target.value)}}>
                <option value="FamilyID">Family ID</option>
                </Form.Select>
              </Form.Group>
            </Col>
          <Col  className="mb-3" md={8}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
            <Form.Control type="text" autoFocus placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>
              <Button variant="primary" type="submit" onClick={(event)=>{handleSearch()}}>Submit</Button>
            </InputGroup>
          </Col>
        </Row>
      </div>

      {searchResults.length>0 && <>
        <div style={{textAlign:"right"}}> 
          <FontAwesomeIcon  style={{float:"center"}} size="2x" icon={faCalendarCheck} onClick={handleToggle}/>
        </div>
        
        {viewCal && <div style={{overflowY: "scroll", height:"200px"}}>          
        <Card style={{padding:"10px"}}>
        <PaymentMonthDisplay />
        </Card>
        </div>}
        <div style={{paddingTop:"30px"}}>
        <PaymentForm formType={formType}/>
        </div>
      </>}

    </div>
    );
}

export default AddPayments;