import React, {useEffect, useContext, useState} from 'react';
import { Row, Card} from 'reactstrap';
import axios from 'axios';
import {Alert  } from "reactstrap";
import { Auth } from 'aws-amplify';
import Preloader from "../../components/Preloader";
//import CoursePaymentInsights from './CoursePaymentInsights'
//import RegistrationInsights from './RegistrationInsights'
import { ProductContext } from '../../ProductProvider';
//import {useHistory} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import SubscriberInsights from './SubscriberInsights';
//import GoogleBarChart from './GoogleBarChart';
const config = require('../../config.json');
//const graphSubColours=["color:green","color:orange","color:silver","color:grey","color:red"];


export default function MemberInsights() {
  //let history=useHistory();
  const { loggedUser, validateLogin, alertClose, alertDetails, setAlertDetails, churchMaster} = useContext(ProductContext);
  const [loaded, setLoaded] = useState(false);
  const [googleSubData, setGoogleSubData] = useState();
  const [googleCatData, setGoogleCatData] = useState();
  const [googleActiveSubData, setGoogleActiveSubData] = useState();
  //const [subStatusCount, setSubStatusCount] = useState();
  

  useEffect(()=>{
    alertClose();
    if(!loggedUser.isAuthenticated){
      validateLogin();
      }else{
        fetchSubscriberInsights();
      }
  },[loggedUser])

  const fetchSubscriberInsights=async()=>{
    try{      
        
          setLoaded(false);
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;  
          await axios.get(`${config.api.invokeUrl}/dashboard/insights/members?clientID=${loggedUser.familyName}`,{
            headers: {
            Authorization: access_token,
            'x-api-key':config.api.key
          }
        }).then(async(response) => {
         //console.log("response : ", response);
         await processSubscriberData(response.data[0],"Anibyam");
         await processSubscriberData(response.data[1],"MembersCount");
          setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: "Family Member Insights processed successfully!"
        });  
          setLoaded(true);
  
      }).catch((error)=>{
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      });        
      setLoaded(true);
    })
  
      }catch(error){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      });        
      setLoaded(true);
      }
    }

const processSubscriberData=(rawData, type)=>{
    var subChartHeader=[];
    //var subChartValues=[];
    const chartHearder=["Status",type];
    subChartHeader.push(chartHearder);
  //  var i=0;
    for(const data in rawData){
        const ttt=[data, rawData[data]];
        subChartHeader.push(ttt);
      //  i++;
       // regChartHeader.push(rawData[data]);
        //subChartValues.push(rawData[data]);
        //console.log(data," - ", rawData[data]);
    }
    //const subChartData={};
    //subChartData.labels=subChartHeader;
    //subChartData.series=subChartValues;
    //setSubStatusCount(subChartData);
    //console.log("Data : ", subChartHeader);
    //var tdata=[]
    //tdata.push(chartHearder);
    //tdata.push(subChartHeader);
    //tdata.push(subChartValues);
    if(type=="Anibyam"){
    setGoogleSubData(subChartHeader);
    const tactiveData=subChartHeader.filter(item=>item[0]!=="Cancelled");
    setGoogleActiveSubData(tactiveData);
    //console.log(tactiveData);
    }
    else{
    setGoogleCatData(subChartHeader);
    }
    //setIsRegYearCount(true);
}

  return (
    <>
    <Helmet>
    <title>Insights and Analytics - Admin Office - {churchMaster.chruchFullName}</title>
      <meta name="description" content="Insights and Analytics - Church Admin Office" />
      <meta name="keywords" content="Insights and Analytics - Church Admin Office" /> 
    </Helmet>
    <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
    {alertDetails.message}
    </Alert>
    <div className='container'><h3>Family Card Insights</h3></div>
    <Card><Row><SubscriberInsights googleData={googleSubData} title={"Family Card Zone Insights"}/></Row></Card>
    {/*<Card><Row><GoogleBarChart googleData={googleActiveSubData} title={"Active Subscriber Break Up"}/></Row></Card>*/}
    <Card><Row><SubscriberInsights googleData={googleCatData} title={"Family Members Insights"}/></Row></Card>
    </>
  )
}
